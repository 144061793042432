import { Validators } from "@angular/forms";

export const loginFieldConfigs = [
  {
    type: 'input',
    name: 'email',
    fieldType: 'text',
    value: '',
    placeholder: 'Email',
    class: 'email',
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  {
    type: 'input',
    name: 'password',
    fieldType: 'password',
    value: '',
    placeholder: 'Password',
    class: 'password',
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
];

