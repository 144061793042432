<div class="main-wrapper">
  <div class="top-bar">
    <h1>{{ floorData.label}}</h1>
    <div class="grofenceBtn">
      <app-button-field (click)="saveGeofence()">Save</app-button-field>
      <app-button-field (click)="goBack()">Back</app-button-field>
    </div>
  </div>
  <div class="top-section">
    <div class="geofence-tree">
      <div class="geofence-input">
        <span class="p-float-label">
          <input id="float-input" type="text" [(ngModel)]="geofenceName" pInputText>
          <label for="float-input">Enter Geofence Name</label>
        </span>
        <div class="category-wrapper">
          <p-dropdown [options]="categories"  [(ngModel)]="selectedCategory" optionLabel="name"
            placeholder="Select a Category">
          </p-dropdown>
          <button class="add-category-btn" (click)="createNewCategory()"><i class="pi pi-plus"></i></button>
        </div>
        <p-colorPicker pTooltip="Pick color" tooltipPosition="left" [(ngModel)]="geofenceColor"></p-colorPicker>
      </div>

      <app-geofence-listing 
       [geofences]="geofences"
       (highlight)="highlightGeofence($event)" 
       (deleteSelectedGeofence)="deleteSelectedGeofence($event)"  
       (onEditGeofence)="onEditGeofence($event)">
      </app-geofence-listing>
      
    </div>
    <div id="map" class="map"></div>
  </div>
</div>
<p-dialog [style]="{width: '50vw'}" class="geofence-dialog-wrapper" header="Add Geofence Category" [(visible)]="displayCategoryDialog" (onHide)="onHide($event)">
    <app-dynamic-form-builder class="add-category-form" #form [config]="categoryConfig"></app-dynamic-form-builder>
    <app-widgets
    [tableConfig]="tableConfig"
    (deleteFacilityDetail)="onDeleteCategory($event)"
    (editFacilityDetail)="onEditCategory($event)">
    </app-widgets>
    <app-confirm-popup></app-confirm-popup>
    <p-footer>
      <button pButton (click)="saveCategory(form?.form)">{{btnText}}</button>
    </p-footer>
</p-dialog>