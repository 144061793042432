import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';
import { UtilityService } from 'src/app/shared/utility-services/utility.service';
import { mapConfig } from '../models/campus.model';

@Injectable({
    providedIn: 'root'
})
export class CampusService {

    url: any = environment.apiUrl;
    endPoints = ApiEndpoints.Campus
    http = inject(HttpClient);
    utility = inject(UtilityService)
    getFacilitiesCampus() {
        const url = `${this.url}${this.endPoints.CampusFacilities}`;
        return this.http.get<any>(url);
    }

    getDeviceSummary(payload: any) {
        const headers = new HttpHeaders({
            'spinner': 'false'
        });
        const url = `${this.url}${this.endPoints.DeviceSummary}`;
        return this.http.post<any>(url, payload, { headers: headers });
    }

    getAllDevices(payload?: any) {
        let queryParams: string[] = [];
    
        if (payload) {
            if (payload.facilityId) {
                queryParams.push(`facilityId=${payload.facilityId}`);
            }
            if (payload.buildingId) {
                queryParams.push(`buildingId=${payload.buildingId}`);
            }
            if (payload.floorId) {
                queryParams.push(`floorId=${payload.floorId}`);
            }
            if (payload.groupId) {
                queryParams.push(`groupId=${payload.groupId}`);
            }
        }
    
        const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
        const url = `${this.url}${this.endPoints.allDevice}${queryString}`;
    
        return this.http.get<any>(url);
    }

    getSavedMapConfig(){
        const url = `${this.url}${this.endPoints.MapConfig}`;
        return this.http.get<any>(url);
    }

    saveMapConfig(payload:mapConfig){
        const url = `${this.url}${this.endPoints.MapConfig}`;
        return this.http.post<any>(url, payload);
    }
    
}
