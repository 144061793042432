import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EventService {

  loaderService = new Subject<boolean>();

  deviceService = new BehaviorSubject<any>('');

  constructor() { }


}
