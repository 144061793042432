import { Component, OnInit, ViewChild } from '@angular/core';
import { deviceFieldConfigs } from './add-device-constant';
import { devicePayload } from './deviceModels/device.model';
import { DeviceTypeService } from './components/add-device-type/services/device-type.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DevicesService } from './services/devices.service';
import { CsvImportComponent } from 'src/app/shared/ui-components/dailogs/csv-import/csv-import.component';
import { HeadersObj } from 'src/app/shared/constants/constants';
import { ControlName } from 'src/app/shared/enums/settings.enum';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { MarkersService } from '../settings/components/marker/services/markers.service';
import { UtilityService } from 'src/app/shared/utility-services/utility.service';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})

export class AddDeviceComponent implements OnInit {
  @ViewChild('form') form!: any;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;

  deviceConfig: any[] = deviceFieldConfigs;

  showFormSection: boolean = false;
  editDeviceRowData: any = [];
  widgetDeviceData: any = [];
  ref: any;
  tableConfig: any = [];

  constructor(
    private deviceService: DevicesService,
    private markerService: MarkersService,
    private utility: UtilityService,
    private deviceTypeService: DeviceTypeService,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.getDeviceWidgets();

  }

  getDeviceTypeWidgetDetail() {
    this.deviceTypeService.getDeviceTypes('', HeadersObj).subscribe((DevicesTypes: any) => {
      this.createOptionsConfig(DevicesTypes.data, "tagType");
    })
  }
 
  getMarkers() {
    this.markerService.getAllMarkers('', HeadersObj).subscribe((markers: any) => {
      this.createOptionsConfig(markers.data, ControlName.marker);
    });
  }

  createOptionsConfig(list: any, controlName: string) {
    let controlIndex = this.deviceConfig.findIndex((control: any) => control.name === controlName);
    if (controlIndex > -1) {
      this.deviceConfig[controlIndex].options = list?.map((option: any) => ({ 'name': option?.title || option?.name || option?.label || option?.gateway_id, 'id': option.id, image: option?.image || ''  }));
    }
  }

  getDeviceWidgets() {
    this.deviceService.getDevices('').subscribe((res: any) => {
      this.widgetDeviceData = res;
      this.widgetDeviceData.data = this.utility.createBreadcrumb(this.widgetDeviceData.data);
      this.createTableConfig(res.data);
      // this.getGateways();
      this.getMarkers();
      this.getDeviceTypeWidgetDetail();
    })
  }

  showForm() {
    this.showFormSection = true;
    setTimeout(() => {
    }, 0);
  }

  onSaveDeviceDetails(formConfig: any) {
    let payload: devicePayload = {
      ...formConfig?.value
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      if (this.editDeviceRowData?.id) {
        this.updateDeviceDetails(payload);
      } else {
        this.addDeviceDetails(payload);
      }
    }
  }

  addDeviceDetails(payload: devicePayload) {
    this.deviceService.addDevice(payload).subscribe((res: any) => {
      const data = {
        item: res.data,
        image: res.data.marker.image,
        gateway: res.data.gateway !=null? res.data.gateway.gateway_id : '',
        deviceId: res.data.title,
        device: res.data.name,
        action: {
          edit: true,
          delete: true
        }
      }
      this.tableConfig.data.unshift(data);
      this.showFormSection = false;
    });
  }

  onEditDeviceDetail(data: any) {
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.form?.form.reset();
      this.editDeviceRowData = '';
      this.showFormSection = false;
      this.selectedEditRow.selectedRowIndex = -1;
    } else {
      this.selectedEditRow.selectedRowIndex = data.id;
      this.editDeviceRowData = data;
      this.showFormSection = true;
      this.showForm();
      setTimeout(() => {
        this.form?.form.patchValue({
          title: data.title,
          marker: data?.marker?.id,
          name: data?.name,
          dx_position: data?.dx_position,
          dy_position: data?.dy_position,
          tagType: data.tagType.id,
        });
      }, 0)
    }
  }

  updateDeviceDetails(payload: devicePayload) {

    this.deviceService.updateDevices(this.editDeviceRowData.id, payload).subscribe((res: any) => {
      const data = {
        item: res.data,
        image: res.data.marker.image,
        gateway: res.data.gateway !=null? res.data.gateway.gateway_id : '',
        deviceId: res.data.title,
        device: res.data.name,
        action: {
          edit: true,
          delete: true
        }
      }
      for(let i =0;i<this.tableConfig.data.length;i++) {
        if(res.data.id == this.tableConfig.data[i].item.id ) {
          this.tableConfig.data.splice(i,1,data);
        }
      }
      this.editDeviceRowData = '';
      this.selectedEditRow.selectedRowIndex = -1
      this.showFormSection = false;
    });
  }

  onDeleteDeviceDetail(id: number) {
    this.showFormSection = false;
    this.deviceService.deleteDevice(id).subscribe(() => {
      let deleteIndex = this.tableConfig.data.findIndex((item: any) => item.item.id == id);
      if (deleteIndex > -1) {
        // this.widgetDeviceData.data.splice(deleteIndex, 1);
        this.tableConfig.data.splice(deleteIndex, 1);
      }
    });
  }

  importCsvFile(){
    this.ref = this.dialogService.open(CsvImportComponent, { 
      data: {fileType: '.csv', multiple: false, screenType: 'add-device', apiParam: 'file'},
      header: 'Upload CSV',
      width: '50vw',
    });
    this.ref.onClose.subscribe((result: any) => {
      this.getDeviceWidgets();
    });
  }
  
   //create table config
   createTableConfig(gateway: any) {
    const headers = [
        { header: 'Images', field: 'image' },
        { header: 'Gateways', field: 'gateway' },
        { header: 'Device ID', field: 'deviceId' },
        { header: 'Devices', field: 'device' },
        { header: 'Actions', field: 'action' },

      ];
    const data: any = []      
    gateway.forEach( (e:any) => {
        const config = {
            item: e,
            image: e.marker !=null? e.marker.image : null,
            gateway: e.gateway !=null? e.gateway.gateway_id : '',
            deviceId: e.title,
            device: e.name,
            action: {
              edit: true,
              delete: true
            }
        }
        data.push(config)  
    });
    this.tableConfig = {header: headers, data: data};
}

}

