  <div class="cropImageWrapper">
    <div [class.disable]="cropEnabled" class="imageRotatePreview">
      <canvas #canvas id="fabricCanvas"></canvas>
    </div>
    <image-cropper 
      *ngIf="cropEnabled && imageChangedEvent"
      [imageBase64]="imageChangedEvent"
      (imageCropped)="onImageCropped($event)"
      [maintainAspectRatio]="false"
    ></image-cropper>
  </div>

  <div class="controls">
    <div class="imageControlBtn">
      <i class="dripicons-crop" [class.isCropActive]="cropEnabled" tooltipPosition="bottom" (click)="toggleCropMode()" [pTooltip]="cropEnabled ? 'Disable Crop' : 'Enable Crop'"></i> 
      <i (click)="undo()" class="pi pi-replay" tooltipPosition="bottom" pTooltip="Undo"></i>
      <i *ngIf="cropEnabled && imageChangedEvent" class="pi pi-check" (click)="setView()"></i>
    </div>
    <div style="display: flex; justify-content: center; align-items: center;">
      <button (click)="setImage()">Done</button>
    </div>
  </div>

