import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';
import { EventService } from '../../shared/services/event.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  url: any = environment.apiUrl;

  constructor(
    private socket: Socket,
    private eventService: EventService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.url = params['env'];
    });
  }

  connectSocket() {
    this.socket.connect();
    this.connect()
  }

  disconnectSocket() {
    this.socket.disconnect();
  }

  connect(): void {
    if (this.url == 'demo') {
      this.url = "device_info"
    }
    else {
      this.url = "device_data"
    }
    this.socket.emit("subscribe", this.url);
    this.socket.fromEvent(this.url).pipe().subscribe(res => {
      this.eventService.deviceService.next(res);
    })
  }

}

