<div class="multiMessage">
        <div class="formControl" [formGroup]="group">
            <label for="">{{config.placeholder}}</label>
            <p-multiSelect [formControlName]="config.name" optionValue="id" [options]="config.options" optionLabel="name" [placeholder]="'--Select--'" [required]="config.isRequired">
            </p-multiSelect>
        </div>
    <div *ngIf="!group.get(config.name)?.valid && group.get(config.name)?.touched" class="errorMassage">
        This field is required!
    </div>
</div>


