import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent {
  @Input() groupsList: any;
  @Output() selectedGroup = new EventEmitter<any>()
  isCollapsed: boolean = true;
  selectedItem: any;

  getSelectedItem(item: any) {
    this.selectedItem = item;
    this.selectedGroup.emit(this.selectedItem)  
  }
}
