import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as L from 'leaflet';
import { icon, marker, tileLayer } from 'leaflet';
import 'leaflet-contextmenu';
import 'leaflet-fullscreen';
import 'leaflet.markercluster';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GatewaysService } from 'src/app/feature/settings/components/gateways/services/gateways.service';
import { EventService } from '../../services/event.service';
import { UtilityService } from '../../utility-services/utility.service';
import { DeviceResponse, FilterMenuItem } from './mapModels/map.model';
import { CookieService } from 'ngx-cookie-service';

L.Icon.Default.imagePath = 'assets/'

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnChanges, OnInit {
  @ViewChild('mapContainer') mapContainer!: any;

  @Input() mapConfig: any
  @Input() devices: DeviceResponse[] = [];
  @Input() tableConfig: any = [];
  @Input() screenView: string = ''

  @Output() isHideTreeView = new EventEmitter<any>()
  @Output() isHideGroupView = new EventEmitter<any>()
  @Output() isHideTableView = new EventEmitter<any>()
  @Output() registeredDataPointsConfig = new EventEmitter<any>()

  @Output() resetMap = new EventEmitter<any>()
  searchValue: string | undefined;

  markerIcon: string = 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png'
  dragIcon: string = 'https://img.icons8.com/?size=100&id=18727&format=png&color=#348acc'
  layers: L.Layer[] = [];
  fitBounds: any;
  ref: DynamicDialogRef | undefined;

  markers: any[] = [];
  imageOverlay!: any;
  mapType: any;
  deviceIds: any[] = [];
  registeredDataPoints: any = [];
  filteredDeviceList: any = [];
  alreadyRegistered: any = [];
  gateways: any = [];

  openContextMenu: any = null;

  zoomAnimation = {
    animate: true,
    duration: 2 // Duration of the animation in seconds
  };

  filterMenuList: FilterMenuItem[] = [{
    key: 'all',
    value: 'All'
  },
  {
    key: 'unassigned',
    value: 'Unassigned'
  },
  {
    key: 'assigned',
    value: 'Assigned'
  }]

  options: any = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      })
    ],
    zoom: 7,
    zoomControl: true,
    // center: latLng(51.505, -0.09),
  };

  markerClusterGroup!: L.MarkerClusterGroup;
  googleOriginal!: L.TileLayer;
  draggedDevice: any;

  map!: L.Map;

  customGatewayDragDropControl!: (new (...args: any[]) => { options: { position: string; }; onAdd: (map: any) => HTMLDivElement; }) & typeof L.Control;
  gatewayContainer!: HTMLDivElement;
  labelToggleContainer!: HTMLDivElement;
  filterClass: string = 'pi-filter';
  customTooltipControl!: (new (...args: any[]) => { options: { position: string; }; onAdd: (map: any) => HTMLDivElement; }) & typeof L.Control;
  labelIsChecked = true;
  isAdmin = false;

  constructor(
    private eventService: EventService,
    private gatewayService: GatewaysService,
    public dialogService: DialogService,
    private utility: UtilityService,
    private cd: ChangeDetectorRef,
    private cookieService: CookieService) {
    eventService.deviceService.asObservable().subscribe(device => {
      this.layers.map((marker: any) => {
        if (marker?._popup?._content == device?.deviceId) {
          marker.setLatLng([device.cartesianCoordinates.x, device.cartesianCoordinates.y]);
        }
      });
    });
  }
  ngOnInit(): void { 
    if(this.cookieService.check('IS_ADMIN'))
    this.isAdmin = JSON.parse(this.cookieService.get('IS_ADMIN'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tableConfig?.data) {
      this.filteredDeviceList = [...this.tableConfig.data];
    }
    this.gateways = [];
    this.markers = [];
    this.layers = [];
    this.markerClusterGroup = L.markerClusterGroup();
    const mapConfig  = this.mapConfig[0];
    if (mapConfig.level == 'floor') {
      if (mapConfig.gateway) {
        for (let gateway of mapConfig.gateway) {

          const gatWayImage = this.createMarker({ id: gateway.id, coordinates: [ gateway.dx_position, gateway.dy_position], iconUrl: gateway?.image || this.markerIcon, popupContent: gateway.gateway_id, draggable: false, gatewayDetail: gateway });
          this.gateways.push(gatWayImage);
        }
      }
      for (let config of this.devices) {
        this.deviceIds.push(config.id);
        let activeDevices = JSON.parse(localStorage.getItem('activeDevices') || '');
        if (activeDevices.includes(config['vehicle_id'] || config['id'])) {
          const markerConfig = this.createMarker({ id: config.id, coordinates: [config.dx_position, config.dy_position], iconUrl: config?.marker?.image || this.markerIcon, popupContent: config.name || config.title ||config. label, draggable: false });
          this.markers.push(markerConfig);
        }
      }
      setTimeout(() => {
        if(mapConfig.image){
          this.addOverlayImage(mapConfig.image, mapConfig.dx_position, mapConfig.dy_position);
        }
      }, 10);
      if (this.gatewayContainer) {
        this.gatewayContainer.style.display = 'inline';
      } else if(this.labelToggleContainer) {
        this.labelToggleContainer.style.display = 'inline';
      }
      return;
    }

    if (this.imageOverlay) {
      this.map.removeLayer(this.imageOverlay);
      this.map.addLayer(this.googleOriginal);
    }
  }

  ngAfterViewInit() {
    this.googleOriginal = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 50,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    });
    this.map.addLayer(this.googleOriginal);

    this.map.zoomControl.setPosition('bottomleft');

    (L.control as any).fullscreen({
      position: 'bottomleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
      title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
      titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
      content: null, // change the content of the button, can be HTML, default null
      forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
      forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
      fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
    }).addTo(this.map);

    if (this.screenView !== 'facility') {
      const customControl = L.Control.extend({
        options: {
          position: 'bottomright'
        },

        onAdd: (map: any) => {
          const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
          container.style.backgroundColor = 'white';
          container.style.padding = '5px 10px';
          let icon = 'pi-chevron-down'
          container.innerHTML = '<i class="pi pi-chevron-down"></i>';

          container.onclick = () => {
            this.isHideTableView.emit();
            icon = icon === 'pi-chevron-down' ? 'pi-chevron-up' : 'pi-chevron-down';
            container.innerHTML = `<i class="pi ${icon}"></i>`;
            setTimeout(() => {
              this.map.invalidateSize();
            }, 400);
          }
          return container;
        }
      });

      if(this.isAdmin) {
        this.customGatewayDragDropControl = L.Control.extend({
          options: {
            position: 'topright'
          },
          onAdd: (map: any) => {
            this.gatewayContainer = L.DomUtil.create('div', 'enableGateway deviceListing');
            this.gatewayContainer.style.padding = '5px 10px';
            this.gatewayContainer.style.display = 'inline';
            
            // Create the toggle switch with tooltip container
            let tooltipContainer: any = document.createElement('div');
            tooltipContainer.classList.add('tooltip-container');
            
            let label = document.createElement('span');
            label.classList.add('toggle-label');
            label.innerText = `Drag Gateway:`;
            
            let toggleSwitch: any = document.createElement('label');
            toggleSwitch.classList.add('switch');
            toggleSwitch.innerHTML = `
            <input type="checkbox">
            <span class="slider round"></span>`;
          
            // Add the toggle switch to the tooltip container
            tooltipContainer.appendChild(label);
            tooltipContainer.appendChild(toggleSwitch);
            
            // Create the tooltip text
            // let tooltipText: any = document.createElement('div');
            // tooltipText.classList.add('tooltip-text');
            // tooltipText.innerText = 'Enable gateways drag';
            
            // Add the tooltip text to the container
            // tooltipContainer.appendChild(tooltipText);
            
            // Add the tooltip container to the gateway container
            this.gatewayContainer.appendChild(tooltipContainer);
            
            // Handle the toggle switch change event
            toggleSwitch.querySelector('input').onchange = (event: any) => {
              const isChecked = event.target.checked;
              
              this.layers.forEach((z: any) => {
                if (this.gateways.includes(z)) {
                  if (isChecked) {
                    z.dragging.enable();
                    z.options.draggable = true;
                  } else {
                    z.dragging.disable();
                    z.options.draggable = false;
                  }
                }
              });
              // tooltipText.innerText = isChecked ? 'Disable gateways drag' : 'Enable gateways drag';
            };
            return this.gatewayContainer;
         }   
        });
        this.map.addControl(new this.customGatewayDragDropControl());
      }
      
      this.customTooltipControl = L.Control.extend({
        options: {
          position: 'topright'
        },

        onAdd: (map: any) => {
          this.labelToggleContainer = L.DomUtil.create('div', 'enableGateway deviceListing');
          this.labelToggleContainer.style.padding = '5px 10px';
          this.labelToggleContainer.style.display = 'inline';

          // Create the toggle switch with tooltip container
          let tooltipContainer: any = document.createElement('div');
          tooltipContainer.classList.add('tooltip-container');

          let label = document.createElement('span');
          label.classList.add('toggle-label');
          label.innerText = `Marker Labels:`;

          let toggleSwitch: any = document.createElement('label');
          toggleSwitch.classList.add('switch');
          toggleSwitch.innerHTML = `
              <input type="checkbox" checked="true">
              <span class="slider round"></span>
          `;

          // Add the toggle switch to the tooltip container
          tooltipContainer.appendChild(label);
          tooltipContainer.appendChild(toggleSwitch);

          // Create the tooltip text
          // let tooltipText: any = document.createElement('div');
          // tooltipText.classList.add('tooltip-text');
          // tooltipText.innerText = 'Hide Labels';

          // Add the tooltip text to the container
          // tooltipContainer.appendChild(tooltipText);

          // Add the tooltip container to the gateway container
          this.labelToggleContainer.appendChild(tooltipContainer);

          // Handle the toggle switch change event
          toggleSwitch.querySelector('input').onchange = (event: any) => {
            this.labelIsChecked = event.target.checked;

            this.layers.forEach((z: any) => {
              if (z.getTooltip()) {
                if (this.labelIsChecked) {
                  z.openTooltip();
                } else {
                  z.closeTooltip();
                }
              }
            });

            // tooltipText.innerText = this.labelIsChecked ? 'Hide Labels' : 'Show Labels';
          };

          return this.labelToggleContainer;
        }
      });

      const customTreeControl = L.Control.extend({
        options: {
          position: 'topleft'
        },

        onAdd: (map: any) => {
          const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control treeControl');
          container.style.backgroundColor = 'white';
          container.style.padding = '5px 7px';
          let icon = 'pi-chevron-left'
          container.innerHTML = `<i class="pi ${icon}"></i>`;

          container.onclick = () => {
            this.isHideTreeView.emit();
            icon = icon === 'pi-chevron-right' ? 'pi-chevron-left' : 'pi-chevron-right';
            container.innerHTML = `<i class="pi ${icon}"></i>`;
            setTimeout(() => {
              this.map.invalidateSize();
            }, 400);
          }
          return container;
        }
      });

      const customGroupControl = L.Control.extend({
        options: {
          position: 'topleft'
        },

        onAdd: (map: any) => {
          const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control treeControl');
          container.style.backgroundColor = 'white';
          container.style.padding = '5px 7px';
          let icon = 'pi-angle-double-left'
          container.innerHTML = `<i class="pi ${icon}"></i>`;

          container.onclick = () => {
            this.isHideGroupView.emit();
            icon = icon === 'pi-angle-double-right' ? 'pi-angle-double-left' : 'pi-angle-double-right';
            container.innerHTML = `<i class="pi ${icon}"></i>`;
            setTimeout(() => {
              this.map.invalidateSize();
            }, 400);
          }
          return container;
        }
      });

      const resetMapControl = L.Control.extend({
        options: {
          position: 'bottomleft'
        },

        onAdd: (map: any) => {
          const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control treeControl');
          container.style.backgroundColor = 'white';
          container.style.padding = '5px 7px';
          let icon = 'pi-chevron-left'
          container.innerHTML = `<i class="pi pi-undo"></i>`;

          container.onclick = () => {
            this.resetMap.emit();
            this.map.flyTo([51.506, -0.09], 3, this.zoomAnimation);
            // icon = icon === 'pi-chevron-right' ? 'pi-chevron-left' : 'pi-chevron-right';
            // container.innerHTML = `<i class="pi ${icon}"></i>`;
          }

          return container;
        }
      });

      this.map.addControl(new customControl());
      this.map.addControl(new customGroupControl());
      this.map.addControl(new customTreeControl());
      this.map.addControl(new resetMapControl());
      this.map.addControl(new this.customTooltipControl());
    } else{
      this.customTooltipControl = L.Control.extend({
        options: {
          position: 'topright'
        },

        onAdd: (map: any) => {
          this.labelToggleContainer = L.DomUtil.create('div', 'enableGateway deviceListing');
          this.labelToggleContainer.style.padding = '5px';
          this.labelToggleContainer.style.display = 'inline';

          // Create the toggle switch with tooltip container
          let tooltipContainer: any = document.createElement('div');
          tooltipContainer.classList.add('tooltip-container');

          let label = document.createElement('span');
          label.classList.add('toggle-label');
          label.innerText = `Marker Label:`;

          let toggleSwitch: any = document.createElement('label');
          toggleSwitch.classList.add('switch');
          toggleSwitch.innerHTML = `
              <input type="checkbox" checked="true">
              <span class="slider round"></span>
          `;

          // Add the toggle switch to the tooltip container
          tooltipContainer.appendChild(label);
          tooltipContainer.appendChild(toggleSwitch);

          // Create the tooltip text
          // let tooltipText: any = document.createElement('div');
          // tooltipText.classList.add('tooltip-text');
          // tooltipText.innerText = 'Hide Labels';

          // Add the tooltip text to the container
          // tooltipContainer.appendChild(tooltipText);

          // Add the tooltip container to the gateway container
          this.labelToggleContainer.appendChild(tooltipContainer);

          // Handle the toggle switch change event
          toggleSwitch.querySelector('input').onchange = (event: any) => {
            this.labelIsChecked = event.target.checked;

            this.layers.forEach((z: any) => {
              if (z.getTooltip()) {
                if (this.labelIsChecked) {
                  z.openTooltip();
                } else {
                  z.closeTooltip();
                }
              }
            });

            // tooltipText.innerText = this.labelIsChecked ? 'Hide Labels' : 'Show Labels';
          };

          return this.labelToggleContainer;
        }
      });
      this.map.addControl(new this.customTooltipControl());
    }    
  }

  addMarkers() {
    // this.markerClusterGroup.clearLayers();
    this.markers.forEach(marker => {
      this.layers.push(marker);
    });
    // this.layers.push(this.markerClusterGroup);
  }

  // To create simple markers for the campus screen.
  createMarker(markerData: any): L.Marker {
    const { coordinates, popupContent, iconUrl, draggable, gatewayDetail } = markerData;
    const draggableMarker = marker(coordinates, {
      draggable: draggable,
      icon: iconUrl ? icon({
        iconUrl: iconUrl,
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        shadowUrl: 'https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png'
      }) : undefined
    }).bindPopup(popupContent).bindTooltip( popupContent || "No label", { // Add label here
      permanent: true,
      direction: 'bottom'
      });

    // Handle drag end event
    draggableMarker.on('dragend', (event) => {
      this.onMapDragEnd(event, gatewayDetail, draggableMarker);
    });

    // Handle mouseover event to open popup
    draggableMarker.on('mouseover', (event) => {
      if(!this.labelIsChecked) {
        draggableMarker.openPopup();
      }
    });

    // Handle mouseout event to close popup
    draggableMarker.on('mouseout', (event) => {
      draggableMarker.closePopup();
    });

    return draggableMarker;
}

addOverlayImage(imagePath: string, dx: number, dy: number) {
  // Clear the previous image overlay if it exists
  if (this.imageOverlay) {
    this.map.removeLayer(this.imageOverlay);
    this.imageOverlay = null;
  }

  // Define the bounds for the new image overlay
  const imageBounds: any = [
    [0, 0], // Southwest corner
    [dy, dx] // Northeast corner
  ];

  // Add the new image overlay to the map
  this.imageOverlay = L.imageOverlay(imagePath, imageBounds).addTo(this.map);

  // Remove any existing layers except markers
  this.map.eachLayer((layer) => {
    if (layer !== this.imageOverlay && !this.markers.includes(layer)) {
      this.map.removeLayer(layer);
    }
  });

  // Set the desired zoom level
  this.map.setZoom(5);

  // Fit the bounds after a delay
  setTimeout(() => {
    this.map.fitBounds(imageBounds);
  }, 20);

  // Add markers if there are any gateways
  if (this.gateways.length) {
    this.markers = [...this.markers, ...this.gateways];
    if (this.markers.length) {
      this.addMarkers();
    }
  }

  // Create the devices and gateways on the map
  this.createTheDevicesGateways();
}


  // To create the already registered devices and gateway....
  createTheDevicesGateways() {
    this.tableConfig?.data?.forEach((config: any) => {
      if (config.linked) {
        const latLng = {
          lat: config.item.dx_position,
          lng: config.item.dy_position
        }
        const markerConfig = {
          uniqueId: config.item.id,
          id: this.draggedDevice?.vehicle_id || config?.item?.vehicle_id || config.item.id,
          coordinates: [config.item.dx_position, config.item.dy_position],
          iconUrl: config?.item?.image || this.markerIcon,
          popupContent: config.item.gateway_id || config.item?.label || this.draggedDevice?.name || config.item.name,
        }
        // this.toCreateTheMarkerPoints(latLng, markerConfig);
        this.markers.push(this.createDevices(markerConfig));
      }
    });
    this.addMarkers();
    // Sort devices so that linked devices are on top
    this.tableConfig?.data?.sort((a: any, b: any) => b.linked - a.linked);
  }


  onMapReady(map: L.Map) {
    this.map = map;
  }

  onMapDragEnd(points: any, gatewayDetail: any, draggableMarker: any) {
    const { id,
      created,
      deleted,
      image,
      is_master_gateway,
      modified,
      uploaded_map,
      ...payload } = gatewayDetail;
    payload.icon = gatewayDetail.icon.id
    payload.dx_position = points.target._latlng.lat;
    payload.dy_position = points.target._latlng.lng;
    payload.facility = gatewayDetail?.facility?.id,
      payload.building = gatewayDetail?.building?.id,
      payload.floor = gatewayDetail?.floor?.id
    delete payload.label
    delete payload.device
    delete payload.customer

    this.gatewayService.updateGateway(gatewayDetail.id, this.utility.toCreateFormData(payload)).subscribe({
      next: (response: any) => {
        gatewayDetail.dx_position = points.target._latlng.lng;
        gatewayDetail.dy_position = points.target._latlng.lat;
      },
      error: (err) => {
        draggableMarker.setLatLng([gatewayDetail.dy_position, gatewayDetail.dx_position])
      },
      complete() { }
    });
  }

  onArrowUpDown() {
    this.isHideTreeView.emit();
  }

  // Device Drag and drop..........
  dragStart(product: any) {
    this.draggedDevice = product;
  }

  createDevices(device: any): any {
    const markerOptions: any = {
      id: device.id,
      icon: L.icon({
        iconUrl: device.iconUrl,
        iconSize: [25, 41], // Adjust the icon size as needed
        iconAnchor: [12, 41], // Adjust the icon anchor as needed
        popupAnchor: [1, -34], // Adjust the popup anchor as needed
        tooltipAnchor: [16, -28], // Adjust the tooltip anchor as needed
      }),
      contextmenu: true,
      contextmenuInheritItems: false,
      contextmenuItems: [
        {
          text: '<i class="pi pi-pen-to-square"></i> Rename Device',
          callback: () => {
            this.renameMarker(device)
            this.closeContextMenu();
          },
          index: 0
        },
        {
          text: '<i class="pi pi-trash"></i> Remove Device',
          callback: () => {
            this.removeMarker(device)
            this.closeContextMenu();
          },
          index: 1
        },
        {
          text: '<i class="pi pi-arrows-alt"></i> Draggable',
          callback: () => {
            this.toggleDraggable(device)
            this.closeContextMenu();
          },
          index: 2
        }
      ]
    };

    const marker = L.marker([device.coordinates[0], device.coordinates[1]], markerOptions).bindPopup(device.popupContent).bindTooltip(
      device.popupContent || "No label", { // Add label here
      permanent: true,
      direction: 'bottom'
      });

    marker.on('contextmenu', (event) => {
      if (this.openContextMenu) {
        this.closeContextMenu();
      }
      this.openContextMenu = event.target;
      // Custom context menu opening logic here
      // If using Leaflet context menu plugin, it will handle the UI part
    });
    this.map.on('click', (e) => {
      if (this.openContextMenu) {
        this.closeContextMenu();
      }
    });

    // Handle mouseover event to open popup
    marker.on('mouseover', (event) => {
      if(!this.labelIsChecked) {
        marker.openPopup();
      }
    });

    // Handle mouseout event to close popup
    marker.on('mouseout', (event) => {
      if(!this.labelIsChecked) {
        marker.closePopup();
      }
    });
    
    return marker;
  }

  closeContextMenu() {
    if (this.openContextMenu) {
      this.openContextMenu._hideContextMenu()
      this.openContextMenu = null;
    }
  }

  // Payload for add devices and gateways
  createPayload(element: any, isDeleted: boolean = false) {
    return {
      "isDeleted": isDeleted,
      "floorId": element?.floorId?.id || this.mapConfig[0].id,
      "deviceId": element.vehicle_id,
      "dx_position": element.dx_position,
      "dy_position": element.dy_position,
      "label": element?.label || element.name,
      "id": element?.id
    }
  }

  // when drag device or gateway form the table to floor image...
  drop(event: any) {
    // To get the coordinates of dropped position
    const container = this.mapContainer.nativeElement;
    const { x, y } = this.getDropCoordinates(event, container);
    const latLng = this.map.containerPointToLatLng([x, y]);

    // Create the new marker configuration
    this.draggedDevice.dx_position = latLng.lat,
      this.draggedDevice.dy_position = latLng.lng
    if (this.draggedDevice) {
      this.registeredDataPoints.push(this.createPayload(this.draggedDevice));
    }
    const markerConfig = {
      id: this.draggedDevice?.vehicle_id || this.draggedDevice.id,
      coordinates: [this.draggedDevice.dx_position, this.draggedDevice.dy_position],
      iconUrl: this.draggedDevice?.image || this.markerIcon,
      popupContent: this.draggedDevice?.gateway_id || this.draggedDevice?.label || this.draggedDevice?.name,
    }

    // this.toCreateTheMarkerPoints(latLng, markerConfig);
    this.markers.push(this.createDevices(markerConfig));
    // Uncomment this line to actually call the service to link the device to the floor
    this.addMarkers();
    // Sort devices so that linked devices are on top
    this.tableConfig.data.sort((a: any, b: any) => b.linked - a.linked);
    this.tableConfig.data.map((item: any) => {
      if ((item?.item?.vehicle_id || item?.item?.id) == markerConfig.id) {
        item.draggable = false
      }
    })
  };

  // toCreateTheMarkerPoints(latLng: any, markerConfig: any) {
  //   this.markers.push(this.createDevices(markerConfig));
  //   this.addMarkers();
  //   // Sort devices so that linked devices are on top
  //   this.tableConfig.data.sort((a: any, b: any) => b.linked - a.linked);
  // }

  toggleDraggable(item: any) {
    const marker: any = this.layers.find((m: any) => m.options.id === item.id);
    if (!marker) return;


    const isDraggable = marker.options.draggable;
    marker.dragging[isDraggable ? 'disable' : 'enable']();
    marker.options.draggable = !isDraggable;

    // Change the marker icon based on the draggable state
    const icon = L.icon({
      iconUrl: isDraggable ? this.markerIcon : this.dragIcon,
      iconSize: [!isDraggable ? 35 : 25, 41], // Adjust the icon size as needed
      iconAnchor: [12, 41], // Adjust the icon anchor as needed
      popupAnchor: [1, -34], // Adjust the popup anchor as needed
      tooltipAnchor: [16, -20], // Adjust the tooltip anchor as needed
    });
    marker.setIcon(icon);

    if (!isDraggable) {
      if (!this.alreadyRegistered.includes(item.id)) {
        const selectedDevice = this.tableConfig.data.find((d: any) => (d.item.vehicle_id || d.item.id) === item.id);
        if (selectedDevice) {
          this.registeredDataPoints.push(this.createPayload(selectedDevice.item));
          this.alreadyRegistered.push(item.id);
        }
      }
    }

    // Update coordinates only for the specific device
    const registeredDevice = this.registeredDataPoints.find((config: any) => (config.deviceId || config.id) === item.id);
    if (registeredDevice) {
      registeredDevice.dx_position = marker._latlng.lat;
      registeredDevice.dy_position = marker._latlng.lng;
    }

    this.cd.detectChanges();
  }


  updateContextMenu(marker: any, isDraggable: boolean) {
    const contextMenu = [
      {
        text: 'Rename Device',
        callback: () => { this.renameMarker(marker); }
      },
      {
        text: 'Remove Device',
        callback: () => { this.removeMarker(marker); }
      },
      {
        text: isDraggable ? 'Disable Drag' : 'Enable Drag',
        callback: () => { this.toggleDraggable(marker); }
      }
    ];

    marker.bindContextMenu({
      contextmenu: true,
      contextmenuItems: contextMenu
    });
  }

  removeMarker(config: any) {
    const markerIndex = this.markers.findIndex(item => item.options.id == config.id);
    if (markerIndex !== -1) {
      // Remove the marker from the markers array
      const markerToRemove = this.markers[markerIndex];
      this.markers.splice(markerIndex, 1);
      // this.layers = [...this.markers];
      if (this.openContextMenu && this.openContextMenu.options.id === config.id) {
        this.openContextMenu.unbindContextMenu();
        this.closeContextMenu();
      }
      // Unbind context menu items from the marker
      markerToRemove.unbindContextMenu();
      // Remove the marker from the map layer
      this.map.removeLayer(markerToRemove);
     

      // Update registered data points and table config
      const indexDevice = this.registeredDataPoints.findIndex((item: any) => (item.deviceId || item.id) == config.id);
      if (indexDevice > -1) {
        this.registeredDataPoints.splice(indexDevice, 1);
        for (let item of this.tableConfig.data) {
          if ((item?.item?.vehicle_id || item?.item?.id) == config.id) {
            item.draggable = true;
            item.linked = false;
            item.assign = '';
          }
        }
      }

      if (config.uniqueId) {
        const deletedDeviceIndex = this.tableConfig.data.findIndex((item: any) => item.item.id == config.uniqueId);
        if (deletedDeviceIndex > -1) {
          const deletedItem = this.tableConfig.data[deletedDeviceIndex];
          deletedItem.isDeleted = true;
          deletedItem.assign = '';
          deletedItem.linked = false;
          deletedItem.draggable = true;
          this.registeredDataPoints.push(this.createPayload(deletedItem.item, true));
        }
      }
      this.tableConfig.data.sort((a: any, b: any) => b.linked - a.linked);
        this.cd.detectChanges();
    }
  }

  renameMarker(item: any) {
    const newName = prompt("Enter new name for the marker:", item.label);
    if (newName !== null) {
      item.label = newName; // Update the label property

      this.updateMarkerPopupAndLabel(item); // Update both popup and tooltip
    }
  }

  updateMarkerPopupAndLabel(item: any) {
    const marker = this.layers.find((m: any) =>
      m.getLatLng().lat === item.coordinates[0] && m.getLatLng().lng === item.coordinates[1]
    );
    if (marker) {
      marker.setTooltipContent(item.label); // Update the tooltip content
      const selectedDevice = this.tableConfig.data.find((d: any) => (d.item.vehicle_id || d.item.id) === item.id);
      selectedDevice.item.label = item.label
      this.registeredDataPoints.push(this.createPayload(selectedDevice.item));
    }
  }

  getDropCoordinates(event: DragEvent, container: HTMLElement): { x: number, y: number } {
    const rect = container.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x, y };
  }

  //filter assigned devices
  assignFilter(item: any, op: any) {
    let filterCondition;
    switch (item) {
      case 'assigned':
        this.filterClass = 'pi-filter-slash';
        filterCondition = (device: any) => device.linked;
        break;
      case 'unassigned':
        this.filterClass = 'pi-filter-slash';
        filterCondition = (device: any) => !device.linked;
        break;
      default:
        this.filterClass = 'pi-filter';
        filterCondition = null;
        break;
    }

    // Apply the filter if a condition is specified, otherwise use the entire data array
    this.filteredDeviceList = filterCondition
      ? this.tableConfig.data.filter(filterCondition)
      : [...this.tableConfig.data];

    // Hide the operation if necessary
    if (op && typeof op.hide === 'function') {
      op.hide();
    }
  }

}