import { Validators } from "@angular/forms";

export const categoryFieldConfig = [
    {
        type: 'input',
        name: 'name',
        fieldType: 'text',
        value: '',
        placeholder: 'Category name',
        class: 'type',
        validation: [
            Validators.compose([Validators.required]),
        ],
        isRequired: true,
    },
    {
        type: 'input',
        name: 'description',
        fieldType: 'text',
        value: '',
        placeholder: 'Description',
        class: 'type',
        validation: [
        ],
        isRequired: false,
    },
]