import { Validators } from "@angular/forms";

export const gateWaysFieldConfigs = [
  {
    type: 'input',
    name: 'serial_no',
    fieldType: 'serialNo',
    value: '',
    placeholder: 'Serial No',
    class: 'serialNo',
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
  {
    type: 'input',
    name: 'gateway_id',
    fieldType: 'gateway',
    value: '',
    placeholder: 'Gateway Id',
    tooltipNote: "Please enter gateway locator id here.",
    class: 'gateway',
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
  {
    type: 'input',
    name: 'label',
    fieldType: 'gatewayName',
    value: '',
    placeholder: 'Gateway Name',
    // tooltipNote: "Please enter gateway locator id here.",
    class: 'gateway',
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
  {
    type: 'input',
    name: 'installation_elevation',
    fieldType: 'text',
    value: '',
    placeholder: 'Elevation (in meters)',
    class: 'Elevation',
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  // {
  //   type: 'dropdown',
  //   name: 'facility',
  //   fieldType: 'facility',
  //   value: '',
  //   placeholder: 'Select Facility',
  //   class: 'facility',
  //   options: [],
  //   validation: [
  //     Validators.compose([Validators.required]),
  //   ],
  // },
  // {
  //   type: 'dropdown',
  //   name: 'building',
  //   fieldType: 'building',
  //   value: '',
  //   placeholder: 'Select Building',
  //   class: 'building',
  //   options: [],
  //   validation: [
  //     Validators.compose([Validators.required]),
  //   ],
  // },
  // {
  //   type: 'dropdown',
  //   name: 'floor',
  //   fieldType: 'floor',
  //   value: '',
  //   placeholder: 'Select Floor',
  //   class: 'floor',
  //   options: [],
  //   validation: [
  //     Validators.compose([Validators.required]),
  //   ],
  // },
  {
    type: 'dropdown',
    name: 'icon',
    fieldType: 'markers',
    placeholder: 'Select Markers',
    class: 'markers',
    options: [],
    validation: [
      Validators.compose([Validators.required])
    ],
  },
  // {
  //   type: 'input',
  //   name: 'installation_latitude',
  //   fieldType: 'text',
  //   value: '',
  //   placeholder: 'Latitude',
  //   class: 'latitude',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
  // {
  //   type: 'input',
  //   name: 'installation_longitude',
  //   fieldType: 'text',
  //   value: '',
  //   placeholder: 'Longitude',
  //   class: 'longitude',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
  // {
  //   type: 'input',
  //   name: 'dx_position',
  //   fieldType: 'text',
  //   value: '',
  //   placeholder: 'DX Position (in meters)',
  //   class: 'dXPosition',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
  // {
  //   type: 'input',
  //   name: 'dy_position',
  //   fieldType: 'text',
  //   value: '',
  //   placeholder: 'DY Position (in meters)',
  //   class: 'dyPosition',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
  // {
  //   type: 'input',
  //   name: 'uploaded_map',
  //   fieldType: 'text',
  //   value: '',
  //   placeholder: 'Upload Map',
  //   class: 'uploadMap',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
];


