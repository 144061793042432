import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { AddDeviceTypeComponent } from 'src/app/feature/add-device/components/add-device-type/add-device-type.component';

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss']
})
export class DropdownFieldComponent {
  config: any;
  group!: FormGroup;
  constructor(private dialogService: DialogService){}

 addDeviceType(){
 const ref =  this.dialogService.open(AddDeviceTypeComponent, { 
    header: 'Device Type',
    width: '50vw',
  })
  ref.onClose.subscribe((result: any) => {
    window.location.reload();
  });
  }
  
}
