import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './authentication/components/login/login.component';
import { AuthGuard } from './core/service/login.service';
import { AddDeviceComponent } from './feature/add-device/add-device.component';
import { CampusComponent } from './feature/campus/campus.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'campus',
                pathMatch: 'full'
            },
            {
                path: 'add-device',
                component: AddDeviceComponent,
                data: {
                    title: 'Devices'
                },
            },
            {
                path: 'campus',
                component: CampusComponent,
                data: {
                    title: 'campus'
                },
            },
            {
                path: 'settings',
                loadChildren: () => import('./feature/settings/settings.module').then(m => m.SettingsModule),
                data: {
                    title: 'settings'
                },
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
