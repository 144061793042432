import { Component, ViewChild } from '@angular/core';
import { deviceFieldConfigs } from './groups-constant';
import { DevicesService } from '../../../add-device/services/devices.service';
import { allFacilityDetails } from '../facilities/facilityModels/facilityModels';
import { GroupsService } from './services/groups.service';
import { groupPayload } from './groupModels/group.model';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent {
  @ViewChild('form') form!: any;

  deviceConfig: any[] = deviceFieldConfigs;

  showFormSection: boolean = false;
  editGroupRowData: any = [];
  widgetDeviceData: any = [];
  groupConfig: any;

  constructor(
    private deviceService: DevicesService,
    private groupsService: GroupsService) { }

  ngOnInit(): void {
    this.getGroups();
    this.getDevice();
  }

  getDevice() {
    // Fetch devices and update the dropdown options
    this.deviceService.getDevices('').subscribe((res: any) => {
       this.createOptionsConfig(res.data, 'devices');

    });
  }

  createOptionsConfig(list: any, controlName: string) {
    let controlIndex = this.deviceConfig.findIndex((control: any) => control.name === controlName);
    if (controlIndex > -1) {
        this.deviceConfig[controlIndex].options = list?.map((option: any) => ({ 'name': option?.title || option?.name || option?.label, 'id': option.id , image: option?.image || '' }));
    }
}
  
  createMultiDropdownConfig(list: any) {
    const configMap = new Map<string, any>();
    list.forEach((element: any) => {
      // Generate a unique key based on facility, building, and floor IDs
      // const key = `${element.facility.id}-${element.building.id}-${element.floor.id}`;
  
      // If the key doesn't exist in the map, create a new entry
      // if (!configMap.has(key)) {
      //   configMap.set(key, {
      //     label: `${element.facility.title} / ${element.building.name} / ${element.floor.label}`,
      //     items: []
      //   });
      // }
  
      // Add the device item to the corresponding map entry
      // configMap.get(key).items.push({ id: element.id, name: element.title });
    });
  
    // Convert the map values to an array and return
    return Array.from(configMap.values());
  }

  getGroups(){
    this.groupsService.getGroups().subscribe((res: any) => {
      this.groupConfig = res.data
    });
  }

  showForm() {
    this.showFormSection = true;
  }

  onSaveGroups(formConfig: any) {
    if (formConfig.invalid) {
        formConfig.markAllAsTouched();
    } else {
        if (this.editGroupRowData && this.editGroupRowData.vehicle_group_id) {
            this.updateGroups(formConfig?.value);
        } else {
            this.addGroups(formConfig?.value);
        }
    }
}

  addGroups(payload: groupPayload) {
    this.groupsService.addGroups(payload).subscribe((res: any) => {
      this.showFormSection = false;
      this.getGroups();
    });
  }

  updateGroups(payload: groupPayload) {
    this.groupsService.updateGroup(this.editGroupRowData.vehicle_group_id, payload).subscribe((res: allFacilityDetails) => {
      this.showFormSection = false;
      this.editGroupRowData = '';
      this.getGroups();
    });
  }

  onEdit(event: Event, form:any) {
    event.stopPropagation();
      this.editGroupRowData = form;
      this.showFormSection = true;
      this.showForm();
      setTimeout(() => {
        this.form?.form.patchValue({
          name: form.name,
          devices: form.devices.flatMap((device: any) => device.id)
        });
      }, 0)
  }
    
  onCancelEdit(event: Event) {
    event.stopPropagation();
    this.showFormSection = false;
    this.editGroupRowData = null;
    this.form?.form.reset();
}

  onDelete(event: Event, id: number) {
    event.stopPropagation();
    this.showFormSection = false;
    this.groupsService.deleteGroup(id).subscribe(() => {
      let deleteIndex = this.groupConfig.findIndex((item: any) => item.vehicle_group_id == id);
      if (deleteIndex > -1) {
        this.groupConfig.splice(deleteIndex, 1);
      }
    });
  }
}

