<div class="facilityDetails">
    <div class="facilityHead">
        <h2>
            <!-- <span class="facilityIcon">
                <img src="../../../../../../assets/images/web_stories-icon.svg" alt="Facility Image" />
            </span> -->
            Groups
        </h2>
    </div>
    
    <div *ngIf="!showFormSection" class="addFacilitiesHead">
        <app-button-field (click)="showForm()">Add Groups</app-button-field>
    </div>
    
    <div *ngIf="showFormSection" class="facilityList facilitiesModuleWrapper">
        <div class="closeElement">
            <i (click)="showFormSection = false; editGroupRowData = null" class="pi pi-times"></i>
        </div>
        <app-dynamic-form-builder class="facilitiesModule" #form [config]="deviceConfig"></app-dynamic-form-builder>
        <div class="docSaveBtn">
            <app-button-field (click)="onSaveGroups(form?.form)">
                {{ editGroupRowData?.vehicle_group_id ? 'Update' : 'Save' }}
            </app-button-field>
        </div>
    </div>
    
    <br>
    
    <p-accordion *ngFor="let item of groupConfig; let i = index">
        <div [class.highlighted]="editGroupRowData === item">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="header-content">
                        <span>{{item.name}}</span>
                        <div class="fActionBtnWraper">
                            <div class="editAction" (click)="onEdit($event, item)" *ngIf="editGroupRowData !== item">
                                <i class="pi pi-pencil"></i>Edit
                            </div>
                            <div class="cancelAction" (click)="onCancelEdit($event)" *ngIf="editGroupRowData === item">
                                <i class="pi pi-times"></i>Cancel
                            </div>
                            <div class="delAction" (click)="onDelete($event, item.vehicle_group_id)">
                                <i class="pi pi-trash"></i>Delete
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="deviceListContainer">
                        <h3>Devices</h3>
                        <ul class="deviceList">
                            <li *ngFor="let device of item.devices; let j = index">
                                {{ device.title }}
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </p-accordionTab>
        </div>
    </p-accordion>
</div>
