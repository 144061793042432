import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getFromLocal(key:string){
    return JSON.parse(localStorage.getItem(key) || '')
  }

  setInLocal(payload:{key:string, value:any}){
    localStorage.setItem(payload.key, JSON.stringify(payload.value));
  }

  clearLocal(){
    localStorage.clear();
  }

  getFromSession(key:string){
    return JSON.parse(sessionStorage.getItem(key) || '');
  }

  setInSession(key:string, value:any){
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  clearSession(){
    sessionStorage.clear();
  }
}
