import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { groupPayload } from '../groupModels/group.model';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  url: any = environment.apiUrl;
  facilitiesEndPoints = ApiEndpoints.settings.Groups;

  http = inject(HttpClient);
  constructor() { }

  addGroups(payload: groupPayload){
    return this.http.post<groupPayload>(this.url + this.facilitiesEndPoints.Groups, payload);
  }

  getGroups(headers?: any){
    const url = `${this.url}${this.facilitiesEndPoints.allGroups}`;
    return this.http.get<any>(url, { headers: headers});
  }

  deleteGroup(id: number) {
    const url = `${this.url}${this.facilitiesEndPoints.Groups}/${id}`;
    return this.http.delete<any>(url);
  }

  updateGroup(id: number, payload: groupPayload) {
    const url = `${this.url}${this.facilitiesEndPoints.Groups}/${id}`;
    return this.http.put<any>(url, payload);
  }
  
}
