<div class="logo"><img src="../../../../assets/images/logo.png"
        alt="Logo"></div>
<ul class="sideMenu">
    <li *ngFor="let item of navItems">
        <div class="sidebarMenu" (click)="item.expand = !item.expand"
        [routerLink]="item.link" [routerLinkActive]="'active'" >
            <i class="{{item.icon}}"></i>
            <p >{{item.title}}</p>
                <span *ngIf="item.children" [class.expand]="item.expand" 
                class="pi pi-angle-down settingDropList"></span>
        </div>
            <ul *ngIf="item.expand && item.children">
                <li *ngFor="let subMenu of item.children" [routerLink]="subMenu.link"
                    [routerLinkActive]="'childActive' " ><span
                        class="{{subMenu.icon}}"></span>{{subMenu.title}}</li>
            </ul>
    </li>
</ul>