<form [formGroup]="group">
    <div class="formControl">
        <label for="">{{config.placeholder}}</label>
        <div class="addSelectIcon">
            <p-dropdown [formControlName]="config.name" optionValue="id" [options]="config.options" optionLabel="name" [placeholder]="'--Select--'" [required]="config.isRequired">
                <ng-template let-option pTemplate="item">
                  <div class="dropdown-item">
                          <img *ngIf="option?.image" [src]="option.image" class="dropdown-image" alt="{{ option.name }} image">
                        <span>{{ option.name }}</span>
                  </div>
                </ng-template>
              </p-dropdown>
                <i *ngIf="config.icon" class="{{ config.icon }} addDevice" (click)="addDeviceType()"></i>
        </div>
    </div>
    
</form>
<div *ngIf="!group.get(config.name)?.valid && group.get(config.name)?.touched" class="errorMassage">
    This field is required!
</div>