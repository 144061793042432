<div class="facilityDetails">
        <div *ngIf="!showFormSection" class="addFacilitiesHead">
            <app-button-field (click)="importCsvFile()"> CSV Import </app-button-field>
            <app-button-field (click)="showForm()"> Add Device </app-button-field>
        </div>
    <div *ngIf="showFormSection" class="facilityList facilitiesModuleWrapper">
        <div class="closeElement" >
            <i (click)="showFormSection = false; editDeviceRowData = null; selectedEditRow.selectedRowIndex = -1" class="pi pi-times"></i>
        </div>
        <app-dynamic-form-builder class="facilitiesModule" #form
            [config]="deviceConfig"></app-dynamic-form-builder>
            <div class="docSaveBtn">
                <app-button-field (click)="onSaveDeviceDetails(form?.form)">  {{ editDeviceRowData?.id ? 'Update' : 'Save' }} </app-button-field>
            </div>
        
    </div>
    <app-widgets [tableConfig]="tableConfig" (deleteFacilityDetail)="onDeleteDeviceDetail($event)"
        (editFacilityDetail)="onEditDeviceDetail($event)"></app-widgets>
</div>