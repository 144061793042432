import { Validators } from "@angular/forms";

export const facilitiesFieldConfigs = [
  {
    type: 'input',
    name: 'title',
    fieldType: 'text',
    value: '',
    placeholder: 'Title',
    class: 'title',
    validation: [
      Validators.compose([Validators.required]),
    ],
    isRequired: true,
  },
  {
    type: 'radio',
    name: 'imageUpload',
    fieldType: 'imageUpload',
    value: false,
    placeholder: 'Connect To Map',
    class: 'imageUpload',
  },
  {
    type: 'radio',
    name: 'imageUpload',
    fieldType: 'connectToMap',
    value: true,
    placeholder: 'Upload Image',
    class: 'connectToMap',
  },
];

export const connectToMapFieldConfigs = [
  {
    type: 'dropdown',
    name: 'map_type',
    fieldType: 'map_type',
    value: '',
    placeholder: 'Maps',
    class: 'map_type',
    options: [{ name: 'Google map', id: 1 }, { name: 'Street map', id: 2 }],
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
  {
    type: 'input',
    name: 'latitude',
    fieldType: 'text',
    value: '',
    placeholder: 'Latitude',
    class: 'latitude',
    validation: [
      Validators.compose([Validators.required]),
    ],
    isRequired: true,
  },
  {
    type: 'input',
    name: 'longitude',
    fieldType: 'text',
    value: '',
    placeholder: 'Longitude',
    class: 'longitude',
    validation: [
      Validators.compose([Validators.required]),
    ],
    isRequired: true,
  },
];

export const commonBuildingsFieldConfigs = [
  {
    type: 'checkbox',
    name: 'point_of_interest',
    fieldType: 'checkbox',
    value: false,
    placeholder: 'POI',
    class: 'point_of_interest',
  },
  {
    type: 'dropdown',
    name: 'facility',
    fieldType: 'facility',
    value: '',
    placeholder: 'Select Facility',
    class: 'facility',
    options: [],
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
]

export const buildingsFieldConfigs = [
  {
    type: 'input',
    name: 'dxPositionX', 
    fieldType: 'text',
    value: '',
    placeholder: 'DX position x-axis (in meters)',
    class: 'dxPositionX',
    validation: [
      Validators.compose([Validators.required]),
    ],
    isRequired: true,
  },
  {
    type: 'input',
    name: 'dxPositionY',
    fieldType: 'text',
    value: '',
    placeholder: 'DX position y-axis (in meters)',
    class: 'dxPositionY',
    validation: [
      Validators.compose([Validators.required]),
    ],
    isRequired: true,
  }
];

export const areaFieldConfigs = [
  {
    type: 'input',
    name: 'title',
    fieldType: 'text',
    value: '',
    placeholder: 'Title',
    class: 'title',
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  {
    type: 'dropdown',
    name: 'building',
    fieldType: 'building',
    value: '',
    placeholder: 'Select Building',
    class: 'building',
    options: [],
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
  {
    type: 'input',
    name: 'dxPositionX',
    fieldType: 'text',
    value: '50',
    placeholder: 'DX position x-axis (in meters)',
    class: 'dxPosition',
    tooltipNote: "Please enter the x-axis value, which is the width of the floor.",
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  {
    type: 'input',
    name: 'dxPositionY',
    fieldType: 'text',
    value: '50',
    placeholder: 'DX position y-axis (in meters)',
    class: 'dxPosition',
    tooltipNote: "Please enter the y-axis value, which is the height of the floor.",
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
];

