import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { markerPayload } from '../markersModels/markers.model';
import { Observable, forkJoin } from 'rxjs';
import { UtilityService } from 'src/app/shared/utility-services/utility.service';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';
import { EventService } from 'src/app/shared/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class MarkersService {
  url: any = environment.apiUrl;
  endPoints = ApiEndpoints.settings.Markers;
  http = inject(HttpClient);
  constructor(private utility: UtilityService, private eventService: EventService) { }
  
  getAllMarkerIcons(payload: string | HttpParams){
    return this.http.get<any>(this.url + this.endPoints.GetAllIcons);
  }

  addMarker(payload:any){
    return this.http.post<markerPayload>(this.url + this.endPoints.Makers, payload);
   }
 
   getAllMarkers(payload: string | HttpParams, header?: any){
     const headers = this.utility.createHeaders(header);
     const url = `${this.url}${this.endPoints.Makers}?pagination=false&limit=25&page=1`;
     return this.http.get<any>(url, {headers});
   }
 
   updateMarker(id: number, payload: any) {
     const url = `${this.url}${this.endPoints.Makers}/${id}`;
     return this.http.put<any>(url, payload);
   }

   deleteMarker(id: number) {
    const url = `${this.url}${this.endPoints.Makers}/${id}`;
    return this.http.delete<any>(url);
  }

  getMarkersAndIcons(headers: any): Observable<any> {
    const makersUrl = `${this.url}${this.endPoints.Makers}?pagination=false&limit=25&page=1`;
    const iconsUrl = this.url + this.endPoints.GetAllIcons;
    this.eventService.loaderService.next(true);
    const makersRequest = this.http.get(makersUrl, {headers});
    const iconsRequest = this.http.get(iconsUrl, {headers});

    return forkJoin([makersRequest, iconsRequest]);
  }

  UploadMarker(file: FormData){
    const url = `${this.url}${this.endPoints.uploadMarker}`;
    return this.http.post<any>(url, file);
  }


}

