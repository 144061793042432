<div *ngIf="tableConfig?.data?.length">
    <p-table #dt1 [globalFilterFields]="['item.name']" [value]="filteredDeviceList" [tableStyle]="{ 'min-width': '20rem' }" [columns]="tableConfig.headers">
      <ng-template pTemplate="caption">
        <div class="flex deviceSearchFilterWrapper">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" [(ngModel)]="searchValue" (input)="dt1.filterGlobal(searchValue, 'contains')" placeholder="Search" />
          </span>
          <span>
            <i [ngClass]="['pi', filterClass]" (click)="op.toggle($event)"></i>
            <p-overlayPanel #op styleClass="filterList">
              <ul class="list-none p-0 m-0 flex flex-column gap-3">
                <li *ngFor="let item of filterMenuList; let i = index" (click)="assignFilter(item.key, op)" class="flex align-items-center gap-2">
                  {{ item.value }}
                </li>
              </ul>
            </p-overlayPanel>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns; let i = index">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <ng-container *ngFor="let col of columns; let i = index">
            <td *ngIf="col.field === 'item'" [ngClass]="rowData.draggable ? 'draggableRow' : 'assigned'" [pDraggable]="rowData.draggable" (onDragStart)="dragStart(rowData[col.field])">
              {{ rowData[col.field]['name'] }}
            </td>
            <td *ngIf="col.field === 'assign'">
              <span>{{ rowData[col.field] }}</span><br>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>
  
  <div pDroppable="true" #mapContainer 
       (onDrop)="drop($event)" 
       class="map-container googleMapDevice" 
       leaflet 
       [leafletOptions]="options" 
       [leafletLayers]="layers" 
       [leafletFitBounds]="fitBounds" 
       [draggable]="false" 
       (leafletMapReady)="onMapReady($event)">
  </div>
  