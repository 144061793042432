<h3>Geofence List</h3>
  <div *ngFor="let categories of geofences" [class.selected]="highlightedGeofence === categories">
    <div class="parent-wrapper">
      <div class="geofence-parent-name">
       
        <span class="geofence-text" (click)="highlightGeofence(categories)">
          {{ categories.name }}
        </span>
        <span *ngIf="categories.geofences.length" (click)="toggleExpand(categories)" class="expand-icon">
            <i class="pi" [class.pi-angle-right]="!categories?.expanded" [class.pi-chevron-down]="categories?.expanded"></i>
          </span>
      </div>
      <ng-container *ngIf="categories.expanded">
          <ng-container *ngTemplateOutlet="geofenceList; context: { $implicit: {geofences : categories.geofences, category: categories} }"></ng-container>
      </ng-container>
    </div>
</div>

<ng-template #geofenceList let-geofences>
  <ul *ngIf="geofences.geofences.length">
    <li class="child-wrapper" [class]="geofence?.class" *ngFor="let geofence of geofences.geofences" [class.selected]="highlightedGeofence === geofence">
        <div class="subchild-wrapper">
            <div class="tab-list-wrapper">
                <span (click)="highlightGeofence(geofence)">
                {{ geofence.name }}
                </span>
                
            </div>
            <div class="btn-dropdown-wrapper">
              <div class="action-buttons">
                  <div (click)="editGeofence(geofence, geofences.category)" class="edit-btn btn">
                  <span [pTooltip]="currentGeofence == geofence ? 'Update' : 'Edit'" tooltipPosition="top" [class]=" currentGeofence == geofence ? 'pi pi-check' : 'pi pi-pencil'"></span>
                  </div>
                  <div (click)="deleteGeofence(geofence, $event, geofences.category.id)" class="delete-btn btn">
                  <span  pTooltip="Delete" tooltipPosition="top" class="pi pi-trash"></span>
                  </div>
              </div>
              <span *ngIf="geofence.children.length" (click)="toggleExpand(geofence)" class="expand-icon">
                  <i class="pi" [class.pi-angle-right]="!geofence.expanded" [class.pi-chevron-down]="geofence.expanded"></i>
              </span>
            </div>
        </div>
      <!-- Recursive call for child geofences -->
      <ng-container *ngIf="geofence.expanded">
        <ng-container *ngTemplateOutlet="geofenceList; context: { $implicit: {geofences : geofence.children, category: geofences.category} }"></ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>
