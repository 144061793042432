import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-field',
  templateUrl: './button-field.component.html',
  styleUrls: ['./button-field.component.scss']
})
export class ButtonFieldComponent {
@Input() buttonConfig: any;
@Input() isDisable: boolean = false;
@Output()
clicked: EventEmitter<any> = new EventEmitter<any>();

onClick(){
  this.clicked.emit();
}
}
