import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MarkerComponent } from './components/marker/marker.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DynamicFormBuilderModule } from 'src/app/shared/dynamic-form-builder/dynamic-form-builder.module';
import { SettingsRoutingModule } from './settings.routing';
import { GatewaysComponent } from './components/gateways/gateways.component';
import { FacilitiesComponent } from './components/facilities/facilities.component';
import { GroupsComponent } from './components/groups/groups.component';
import { GeofencingComponent } from './components/geofencing/geofencing.component';
import {ColorPickerModule} from 'primeng/colorpicker';
import { ConfirmationService } from 'primeng/api';


@NgModule({
  declarations: [
    MarkerComponent,
    GatewaysComponent,
    FacilitiesComponent,
    GroupsComponent,
    GeofencingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DynamicFormBuilderModule,
    SettingsRoutingModule,
    ColorPickerModule
  ],
  providers: [
    ConfirmationService 
  ],
})

export class SettingsModule {
}