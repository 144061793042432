import { Component, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { DevicesService } from 'src/app/feature/add-device/services/devices.service';
import { MarkersService } from 'src/app/feature/settings/components/marker/services/markers.service';
interface UploadEvent {
  originalEvent: Event;
  files: File[];
}
@Component({
  selector: 'app-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss']
})
export class CsvImportComponent {
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  uploadedFiles: any[] = [];
  errorMessage: any = [];
  config: any;

  constructor(private deviceService: DevicesService,
    private messageService: MessageService,
    public dialogData: DynamicDialogConfig,
    private markerService: MarkersService
  ) {
    this.config = dialogData.data
  }

  onUpload(event: any) {
    
    const formData = new FormData
      for(let file of event.files) {
          this.uploadedFiles.push(file);
          formData.append(this.config.apiParam, file);
      }
      if(this.config.screenType == 'add-device'){
        this.deviceCSVImport(formData);
      }else{
        this.markerUpload(formData);
      }
  }

  onSelect(event: any) {
  }

  deviceCSVImport(file: FormData){
    this.deviceService.deviceCsvImport(file).subscribe(res =>{
      if(res?.data?.length){
        this.errorMessage = res.data
      }else{
        this.messageService.add({severity: 'success', summary: 'Success', detail: res?.message});
      }
    this.fileUpload.clear();
    })
  }

  markerUpload(file: FormData){
    this.markerService.UploadMarker(file).subscribe(res =>{
        this.messageService.add({severity: 'success', summary: 'Success', detail: res?.message});
        this.fileUpload.clear();
    })
  }
}
