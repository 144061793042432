import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CampusComponent } from './campus.component';


@NgModule({
  declarations: [
    CampusComponent,
  ],
  
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
  ],
})

export class CampusModule {
}