import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventService } from './shared/services/event.service';

@Component({
    selector: 'base-root',
    standalone: false,
    template: ``
  })

export class BaseComponent implements OnDestroy {
  private subscription: Subscription;
  loader : boolean = false;


  constructor(serviceEvent :EventService, cd: ChangeDetectorRef) {
    this.subscription = serviceEvent.loaderService.asObservable().subscribe((loader: any) => {
        this.loader = loader;
        cd.detectChanges();
      })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
