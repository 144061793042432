import { Validators } from "@angular/forms";

export const deviceTypeFieldConfigs = [
      {
            type: 'input',
            name: 'name',
            fieldType: 'text',
            value: '',
            placeholder: 'Device Type',
            class: 'type',
            validation: [
              Validators.compose([Validators.required]),
            ],
            isRequired: true,
          },
]