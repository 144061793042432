export enum SelectedTab {
      facilities = "Facilities",
      buildings = "Buildings",
      floors = "Floors/Area",  
      markers = "Markers",
      gateways = "Gateways",
      devices = "Devices"  
}

export enum ButtonText {
      addFacility = "Add Facility",
      addBuilding = "Add Building",
      addFloor = "Add Floor/Area"
}

export enum ControlName {
      facility = "facility",
      building = "building",
      floor = "floor",
      imageUpload = "imageUpload",
      marker = "marker",
      gateway = "gateway",
      title = "title",
      type = "type"
}