import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BaseComponent } from './base.component';
import { ApiEndpoints } from './core/config/api-endpoints.config';
import { EventService } from './shared/services/event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  color1!: string;
  title = 'Nova';
  url: any = environment.loginUrl;
  endPoints = ApiEndpoints.Auth;

  http = inject(HttpClient);
  constructor(serviceEvent: EventService, private route: ActivatedRoute, cd: ChangeDetectorRef) {
    super(serviceEvent, cd);
  }

  ngOnInit() {
    const currentUrl = window.location.href;
    
    if (currentUrl.includes('token')) {
      const refreshToken = currentUrl.split('?token=')[1];
      sessionStorage.setItem('token', refreshToken);
      sessionStorage.setItem('refreshToken', refreshToken);
      if (refreshToken) {
        sessionStorage.setItem('backToNova', 'true');
        this.http.post<any>(this.url + this.endPoints.RefreshApi, { refreshToken }).subscribe(response => {
          sessionStorage.setItem('token', response?.accessToken);
          sessionStorage.setItem('refreshToken', response.refreshToken);
          // this.route.navigate(['/']);
          sessionStorage.setItem('userDetails', JSON.stringify(response.user));
          const newUrl = currentUrl.split('?')[0]; // Remove everything after '?'
          history.replaceState({}, document.title, newUrl);
        })
      } else {
        console.error('No token found in the URL');
      }
    }

  }
}
