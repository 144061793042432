import { NgModule } from "@angular/core";
import { LoginComponent } from "./components/login/login.component";
import { DynamicFormBuilderModule } from "../shared/dynamic-form-builder/dynamic-form-builder.module";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
    declarations: [
        LoginComponent,
    ],

    imports: [
        CommonModule,
        DynamicFormBuilderModule,
        HttpClientModule
    ],

    exports: [LoginComponent],
    providers: [],
})

export class AuthModule {
}