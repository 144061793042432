import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  createHeaders(obj: any) {
    return new HttpHeaders(obj);
  }

  toCreateFormData(formConfig: any) {
    const formData = new FormData();

    // Loop through the keys of formConfig
    for (const key in formConfig) {
      if (formConfig.hasOwnProperty(key)) {
        if (formConfig[key] !== undefined && formConfig[key] !== null) {
          formData.append(key, formConfig[key]);
        }
      }
    }

    return formData;
  }

  createBreadcrumb(list: any) {
    return list.map((data: any) => {
      const { facility, building, floor, gateway, title, name, label, gateway_id } = data;
      return {
        ...data,
        titles: [
          { label: facility?.title || building?.facility?.title, tooltip: 'Facility' },
          { label: building?.name || name, tooltip: 'Building' },
          { label: floor?.label || label, tooltip: 'Floor' },
          { label: gateway?.gateway_id || gateway_id, tooltip: 'Gateway' },
          { label: title, tooltip: 'Device' }
        ].filter(item => item.label)
      }

    });
  }

  // To convert base64 to blob file............
  base64ToBlob(base64: string, mime: string): Blob {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mime });
  }
}
