import { Component, ViewChild } from '@angular/core';
import { HeadersObj } from 'src/app/shared/constants/constants';
import { SelectedTab } from 'src/app/shared/enums/settings.enum';
import { ImageUploadComponent } from 'src/app/shared/ui-components/image-upload/image-upload.component';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { UtilityService } from 'src/app/shared/utility-services/utility.service';
import { allFacilityDetails, facilities } from '../facilities/facilityModels/facilityModels';
import { MarkersService } from '../marker/services/markers.service';
import { gateWaysFieldConfigs } from './gateways-constant';
import { gatewayPayload } from './gatewaysModels/gateways.model';
import { GatewaysService } from './services/gateways.service';

@Component({
    selector: 'app-gateways',
    templateUrl: './gateways.component.html',
    styleUrls: ['./gateways.component.scss']
})
export class GatewaysComponent {
    @ViewChild('form') form!: any;
    @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;
    @ViewChild(ImageUploadComponent) imageElement!: ImageUploadComponent;
    gateWayConfig: any = gateWaysFieldConfigs;
    showFormSection: boolean = false;

    widgetGatewayData: any = [];
    editGatewayRowData: any = [];
    buildings!: allFacilityDetails;
    floor!: allFacilityDetails;
    selectedTab: string = SelectedTab.gateways;
    tableConfig!: { header: { header: string; field: string; }[]; data: any; };

    constructor(
        private gatewayService: GatewaysService,
        private utility: UtilityService, 
        private markerService: MarkersService,
    ) { }

    ngOnInit(): void {
        this.getGatewayWidgetDetail();
    }

    getMarkers() {
        this.markerService.getAllMarkers('', HeadersObj).subscribe((markers: allFacilityDetails) => {
          this.createOptionsConfig(markers.data, 'icon');
        });
      }


    createOptionsConfig(list: any, controlName: string) {
        let controlIndex = this.gateWayConfig.findIndex((control: any) => control.name === controlName);
        if (controlIndex > -1) {
            this.gateWayConfig[controlIndex].options = list?.map((option: any) => ({ 'name': option?.title || option?.name || option?.label, 'id': option.id , image: option?.image || '' }));
        }
    }

    getGatewayWidgetDetail() {
        this.gatewayService.getGateways('').subscribe((res: allFacilityDetails) => {
            this.createTableConfig(res.data);
            this.getMarkers();
        });
    }

    showForm() {
        this.showFormSection = true;
    }

    onSaveGatewaysDetails(formConfig: any) {
        let payload: gatewayPayload = {
            ...formConfig?.value
        }
        if (formConfig.invalid) {
            formConfig.markAllAsTouched();
        } else {
            if (this.editGatewayRowData?.id) {
                this.updateGatewayDetails(payload);
            } else {
                this.addGatewayDetails(payload);
            }
        }
    }

    addGatewayDetails(payload: gatewayPayload) {
        this.gatewayService.addGateways(this.utility.toCreateFormData(payload)).subscribe((res: any) => {
            const data = {
                item: res.data,
                image: res.data.icon.image,
                facility: res.data.facility != null? res.data.facility.title : '',
                building: res.data.building != null? res.data.building.name : '',
                floor: res.data.floor != null? res.data.floor.label : '',
                gatewayId: res.data.gateway_id,
                gatewayName: res.data.label,
                action: {
                    edit: true,
                    delete: true
                  }
            }
            this.tableConfig.data.unshift(data);
            this.showFormSection = false;
        });
    }

    onEditGatewayDetail(data: facilities) {
        if (this.selectedEditRow.selectedRowIndex === data.id) {
            this.form?.form.reset();
            this.imageElement.clearFileUpload();
            this.editGatewayRowData = '';
            this.selectedEditRow.selectedRowIndex = -1;
        } else {
            this.selectedEditRow.selectedRowIndex = data.id;
            this.editGatewayRowData = data;
            this.showForm();
            setTimeout(() => {
                if (this.imageElement) {
                    this.imageElement.imageUrl = data.image;
                }
                this.form?.form.patchValue({
                    serial_no: data?.serial_no,
                    installation_latitude: data?.installation_latitude,
                    installation_longitude: data?.installation_longitude,
                    installation_elevation: data?.installation_elevation,
                    gateway_id: data?.gateway_id,
                    label: data.label,
                    dx_position: data?.dx_position,
                    dy_position: data?.dy_position,
                    icon: data.icon.id,
                    // facility: data?.facility?.id,
                    // building: data?.building?.id,
                    // floor: data?.floor?.id
                });
            }, 0)
        }
    }

    updateGatewayDetails(payload: gatewayPayload) {
        this.gatewayService.updateGateway(this.editGatewayRowData.id, this.utility.toCreateFormData(payload)).subscribe((res: allFacilityDetails) => {
            this.showFormSection = false;
            let data: any = { ... res.data };
            const config = {
                item: data,
                image: data.icon.image,
                facility: data.facility != null? data.facility.title : '',
                building: data.building != null? data.building.name : '',
                floor: data.floor != null? data.floor.label : '',
                gatewayId: data.gateway_id,
                gatewayName: data.label,
                action: {
                    edit: true,
                    delete: true
                  }
            }
            for(let i =0;i<this.tableConfig.data.length;i++) {
                if(data.id == this.tableConfig.data[i].item.id ) {
                  this.tableConfig.data.splice(i,1,config);
                }
              }
            // this.getGatewayWidgetDetail();
            this.selectedEditRow.selectedRowIndex = -1
            this.editGatewayRowData = '';
        });
    }

    onDeleteDevice(id: number) {
        this.showFormSection = false;
        this.gatewayService.deleteGateway(id).subscribe(() => {
            let deleteIndex = this.tableConfig.data.findIndex((item: any) => item.item.id == id);
            if (deleteIndex > -1) {
                this.tableConfig.data.splice(deleteIndex, 1);
            }
        });
    }

    createTableConfig(gateway: any) {
        const headers = [
            { header: 'Images', field: 'image' },
            { header: 'Facilities', field: 'facility' },
            { header: 'Buildings', field: 'building' },
            { header: 'Floors', field: 'floor' },
            { header: 'Gateways ID', field: 'gatewayId' },
            { header: 'Gateways Name', field: 'gatewayName' },
            { header: 'Actions', field: 'action' },

          ];
        const data: any = []      
        gateway.forEach( (e:any) => {
            const config = {
                item: e,
                image: e.icon !=null? e.icon.image: null,
                facility: e.facility != null? e.facility.title : '',
                building: e.building != null? e.building.name : '',
                floor: e.floor != null? e.floor.label : '',
                gatewayId: e.gateway_id,
                gatewayName: e.label,
                action: {
                    edit: true,
                    delete: true
                  }
            }
            data.push(config)  
        });
        this.tableConfig = {header: headers, data: data};
    }

}
