<ng-container *ngIf="devicesList.length">
    <label class="switch selectAll">
        <input type="checkbox" (change)="onAllCheckboxChange(devicesList, $event.target)" [checked]="this.checkAllSelectCheckBoxStates()">
        <span class="slider round"></span>
    </label>
    <ng-container *ngFor="let item of devicesList">
        <div #scrollPenal [attr.data-id]="(item?.id || item?.vehicle_id)" class="deviceListRow" 
             [id]="highlightDeviceItem?._id == (item?.id || item?.vehicle_id) ? 'highlightDeviceItems' : ''" 
             [class.highlightDeviceItems]="highlightDeviceItem?._id == (item?.id || item?.vehicle_id)">
            <h2 (click)="highlightSelected(item)">{{ item?.name || item?.title }}</h2>
            <div class="switchContainer">
                <div class="switchAlertLabel">
                    <ng-container *ngIf="item.lastUpdate">
                        <p><b>Last Report:</b>  {{ item.lastUpdate }}</p>
                    </ng-container>
                    <ng-container *ngIf="item.lastAlert">
                        <p><b>Last Alert:</b> {{item.lastAlert}}</p>
                    </ng-container>
                </div>
                <!-- Battery level display -->
                <ng-container *ngIf="item?.batteryPercentage != null">
                    <span class="batteryIcon" style="white-space: nowrap ">
                        <i [class]="'battery-icon battery-icon--' + closestIcon(item?.batteryPercentage).mod + ' dripicons-battery-' + closestIcon(item?.batteryPercentage).icon"><span>{{ item?.batteryPercentage  }} %</span></i>
                    </span>
                </ng-container>
                <!-- Location marker for Nova platform -->
                <ng-container *ngIf="item?.platform == 'nova'">
                    <span class="location" (click)="navigateToNovaTracking(item)">
                        <i class="pi pi-map-marker"></i>
                    </span>
                </ng-container>
                <!-- Checkbox for device selection -->
                <label class="switch">
                    <input type="checkbox" (change)="onCheckboxChange(item, $event.target)" [checked]="getActiveIds()?.includes(item?.vehicle_id || item?.id)">
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
    </ng-container>
</ng-container>
<div *ngIf="!devicesList.length && !loader" class="no-data-found">No Data Found.</div>
<div [hidden]="!loader">
    <p-progressSpinner [style]="{width: '40px', height: '40px'}" styleClass="custom-spinner" strokeWidth="2.5"  animationDuration="0.7s"></p-progressSpinner>
</div>