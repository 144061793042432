<div class="card flex justify-content-center">
    <p-fileUpload 
        #fileUpload
        (uploadHandler)="onUpload($event)" 
        [multiple]="config.multiple" 
        [customUpload]="true"
        [accept]="config.fileType" 
        [maxFileSize]="1000000">
            <ng-template pTemplate="content">
                <span class="p-drag-and-drop-message">
                    Drag and drop files here to upload
                  </span>
                    <ul *ngIf="uploadedFiles.length">
                            <li *ngFor="let file of uploadedFiles">
                                {{ file.name }} - {{ file.size }} bytes
                            </li>
                    </ul>
            </ng-template>
    </p-fileUpload>
</div>
    <div *ngIf="errorMessage.length" class="errorMessageAlert">
        <h2>
            {{ errorMessage.length > 0 ? 'No. of error records: ' + errorMessage.length : 'No error records' }}
          </h2>
        
          <div class="error-container">
            <p><strong>Error in:</strong></p>
            <ul class="error-list">
              <ng-container *ngFor="let file of errorMessage">
                <li>
                  <strong>Row {{ file.row }}:</strong> {{ file.message }}
                </li>
              </ng-container>
            </ul>
          </div>

    </div>