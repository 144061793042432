<p-tree #tree class="facilityMenu" [metaKeySelection]="false" [selectionMode]="selection" [(selection)]="selectedNodes" [value]="treeConfig" [filter]="filtering" (onNodeSelect)="selectionChange($event, $event.node)" (onNodeUnselect)="selectionChange($event, $event.node)">
    <ng-template let-node pTemplate="default">
        <span class="ui-treenode-icon">
            <img *ngIf="node.level"
                [src]="getIconPath(node)"
                alt="Facility Image"/>
        </span>
        <span class="ui-treenode-label">{{node.label}}</span> 
        <span class="device-count" *ngIf="node?.devicesCount !== undefined">
            ({{ node['devicesCount'] }})
        </span>
    </ng-template>
</p-tree>
<div *ngIf="!(treeConfig?.length)">
    <h2 class="nodataFound">No Data Found</h2>
</div>
