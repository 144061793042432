import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiEndpoints } from '../../../../core/config/api-endpoints.config';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoginCredentials, LoginDetails } from '../models/login.model';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: any = environment.loginUrl;
  endPoints = ApiEndpoints.Auth;

  http = inject(HttpClient);
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  constructor(private cookieService: CookieService) { }

  refreshToken(): Observable<any> {
    const refreshToken = sessionStorage.getItem('refreshToken') || this.cookieService.get('REFRESH_TOKEN');
    return this.http.post<any>(this.url + this.endPoints.RefreshApi, { refreshToken })
    .pipe(
      tap(response => {
        sessionStorage.setItem('token', response.accessToken);
        this.tokenSubject.next(response.accessToken);
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('An error occurred:', error);
    return throwError(() => error);
  }

  login(credentials: LoginCredentials) {
    return this.http.post<LoginDetails>(this.url + this.endPoints.Login, credentials)
  }
}
