import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-geofence-listing',
  templateUrl: './geofence-listing.component.html',
  styleUrls: ['./geofence-listing.component.scss']
})
export class GeofenceListingComponent implements OnInit {
  @Input() geofences: any
  @Output() highlight: EventEmitter<any> = new EventEmitter();
  @Output() onEditGeofence: EventEmitter<any> = new EventEmitter();
  @Output() deleteSelectedGeofence: EventEmitter<any> = new EventEmitter();

  highlightedGeofence: any;
  currentGeofence: any | null = null;
  drawnItems!: L.FeatureGroup;
  selectedCategory: any;

  constructor() { }

  ngOnInit(): void {
  }

  toggleExpand(geofence: any) {
    geofence.expanded = !geofence.expanded;
  }

  highlightGeofence(geofence: any) {
    this.highlight.emit(geofence);
    this.highlightedGeofence = geofence;
  }

  editGeofence(geofence: any, category: any) {
    this.currentGeofence = geofence;
    const editConfig = {
      geofence: geofence,
      category: category

    }
    this.onEditGeofence.emit(editConfig);
  }

  deleteGeofence(geofence: any, event: Event, categoryId: any) {
    let data = { geofence: geofence, categoryId: categoryId }
    this.deleteSelectedGeofence.emit(data);

    // event.stopPropagation(); // Prevent parent click events

    // if (confirm('Are you sure you want to delete this geofence?')) {
    //   // Find the category that contains the geofence
    //   const category = this.geofences.find((g: any) => g.id === categoryId);

    //   if (category) {
    //     // Check if the geofence is a child of another geofence
    //     if (geofence.parentId) {
    //       const parentGeofence = category.geofences.find((g: any) => g.id === geofence.parentId);

    //       if (parentGeofence) {
    //         // Remove the geofence from the parent's children
    //         parentGeofence.children = parentGeofence.children.filter((child: any) => child.id !== geofence.id);
    //       }
    //     } else {
    //       // Remove the geofence directly from the category's geofences
    //       category.geofences = category.geofences.filter((g: any) => g.id !== geofence.id);
    //     }

        
    //   }
    // }
  }


  isSelectedCategory(geofence: any): boolean {
    return geofence?.id === this.selectedCategory?.id;
  }

}
