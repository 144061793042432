import { Validators } from "@angular/forms";

export const deviceFieldConfigs = [
  {
    type: 'input',
    name: 'title',
    fieldType: 'text',
    value: '',
    placeholder: 'Device Id',
    class: 'title',
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  {
    type: 'input',
    name: 'name',
    fieldType: 'text',
    value: '',
    placeholder: 'Device Name',
    class: 'title',
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  {
    type: 'dropdown',
    name: 'tagType',
    fieldType: 'tagType',
    placeholder: 'Select Type',
    class: 'type',
    options: [],
    validation: [
      Validators.compose([Validators.required])
    ],
    icon: 'pi pi-plus'
  },
  // {
  //   type: 'dropdown',
  //   name: 'facility',
  //   fieldType: 'facility',
  //   value: '',
  //   placeholder: 'Select Facility',
  //   class: 'facility',
  //   options: [],
  //   validation: [
  //     Validators.compose([Validators.required]),
  //   ],
  // },
  // {
  //   type: 'dropdown',
  //   name: 'building',
  //   fieldType: 'building',
  //   value: '',
  //   placeholder: 'Select Building',
  //   class: 'building',
  //   options: [],
  //   validation: [
  //     Validators.compose([Validators.required]),
  //   ],
  // },
  // {
  //   type: 'dropdown',
  //   name: 'floor',
  //   fieldType: 'floor',
  //   value: '',
  //   placeholder: 'Select Floor',
  //   class: 'floor',
  //   options: [],
  //   validation: [
  //     Validators.compose([Validators.required]),
  //   ],
  // },
  {
    type: 'dropdown',
    name: 'marker',
    fieldType: 'markers',
    placeholder: 'Select Markers',
    class: 'markers',
    options: [],
    validation: [
      Validators.compose([Validators.required])
    ],
  },
  // {
  //   type: 'dropdown',
  //   name: 'gateway',
  //   fieldType: 'gateway',
  //   value: '',
  //   placeholder: 'Select Gateway',
  //   class: 'gateway',
  //   options: [],
  //   validation: [
  //     // Validators.compose([Validators.required]),
  //   ],
  // },
  
 
  // {
  //   type: 'input',
  //   name: 'dx_position',
  //   fieldType: 'text',
  //   placeholder: 'DX Position (in meters)',
  //   class: 'dXPosition',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
  // {
  //   type: 'input',
  //   name: 'dy_position',
  //   fieldType: 'text',
  //   placeholder: 'DY Position (in meters)',
  //   class: 'dyPosition',
  //   validation: [
  //       Validators.compose([Validators.required]),
  //     ],
  //     isRequired: true,
  // },
];


