

import { NgModule } from '@angular/core';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';

const imports = [
    CommonModule,
    RouterModule,
    SharedModule,
    RouterModule
]
@NgModule({
    declarations: [
        LayoutComponent,
        SideNavComponent,
    ],

    imports: imports,

    providers: [
    ],
})

export class LayoutModule {
}