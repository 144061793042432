import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
    selector: 'app-device-list-filter',
    templateUrl: './device-list-filter.component.html',
    styleUrls: ['./device-list-filter.component.scss'],
})
export class DeviceListFilterComponent {
  isDivVisible = false;
  searchText: string = '';
  @Input() deviceItems!: any
  @Output() selectedDevice: EventEmitter<any> = new EventEmitter();

  onFocus() {
    this.isDivVisible = true;
  }

  onBlur() {
    // Delay hiding to allow div interaction
    setTimeout(() => {
      this.isDivVisible = false;
    }, 200);
  }
  onDeviceItemClick(item: any) {
    this.selectedDevice.emit(item);
    this.searchText = ''
  }

}
