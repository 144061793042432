<div [formGroup]="group">
    <div class="formControl">
        <label for="">{{config.placeholder}}</label>
        <input [type]="config.fieldType" [required]="config.isRequired" 
            [placeholder]="config.placeholder"  pInputText
            [formControlName]="config.name"
            [pTooltip]="config.tooltipNote" 
            tooltipPosition="bottom" 
            tooltipEvent="focus">
        <span class="pi pi-eye" *ngIf="config.name == 'password'" (click)="(config.fieldType = config.fieldType == 'password' ? 'text' : 'password')"></span>
    </div>
            <div *ngIf="!group.get(config.name)?.valid &&
            group.get(config.name)?.touched" class="errorMassage">
            This field is required!
            </div>
</div>