<div class="searchFieldWrapper">
    <input type="text" placeholder="Search assets" (focus)="onFocus()" (blur)="onBlur()" [(ngModel)]="searchText">
    <ul class="quickSearchList" *ngIf="isDivVisible">
        <p-scroller [items]="deviceItems | search: searchText" [itemSize]="20">
            <ng-template pTemplate="item" let-item>
                <li (click)="onDeviceItemClick(item)">
                    {{ item.value }}
                </li>
            </ng-template>
        </p-scroller>
    </ul>
</div>
