import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { gatewayPayload } from '../gatewaysModels/gateways.model';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';

@Injectable({
  providedIn: 'root'
})
export class GatewaysService {
  url: any = environment.apiUrl;
  endPoints = ApiEndpoints.settings.Gateways;
  http = inject(HttpClient);
  constructor() { }
  
  addGateways(payload: FormData){
   return this.http.post<gatewayPayload>(this.url + this.endPoints.AddGateways, payload);
  }

  getGateways(payload: string | HttpParams, spinner?: any){
    const url = `${this.url}${this.endPoints.GetGateways}?pagination=false&limit=25&page=1`;
    return this.http.get<any>(url);
  }

  deleteGateway(id: number) {
    const url = `${this.url}${this.endPoints.GetGateways}/${id}`;
    return this.http.delete<any>(url);
  }

  updateGateway(id: number, payload: FormData) {
    const url = `${this.url}${this.endPoints.EditGateways}/${id}`;
    return this.http.put<any>(url, payload);
  }

  linkToFloor(payload: any) {
    const url = `${this.url}${this.endPoints.linkToFloor}`;
    return this.http.post<any>(url, payload);
  }
}
