<div class="facilityDetails">
    <div class="facilityHead">
        <h2>
            <!-- <span class="facilityIcon">
                <img src="../../../../../../assets/images/web_stories-icon.svg" alt="Facility Image" />
            </span> -->
            Gateways
        </h2>
    </div>
    
    <div *ngIf="!showFormSection" class="addFacilitiesHead">
        <app-button-field (click)="showForm()"> Add Gateway </app-button-field>
    </div>
    
    <div *ngIf="showFormSection" class="facilityList facilitiesModuleWrapper">
        <div class="closeElement">
            <i (click)="showFormSection = false; editGatewayRowData = null ; selectedEditRow.selectedRowIndex = -1" class="pi pi-times"></i>
        </div>
        <app-dynamic-form-builder #form class="facilitiesModule" [config]="gateWayConfig"></app-dynamic-form-builder>
        <div class="docSaveBtn">
            <app-button-field (click)="onSaveGatewaysDetails(form?.form)">
                {{ editGatewayRowData?.id ? 'Update' : 'Save' }}
            </app-button-field>
        </div>
    </div>
    
    <app-widgets 
        [tableConfig]="tableConfig" 
        [selectedTab]="selectedTab" 
        (deleteFacilityDetail)="onDeleteDevice($event)" 
        (editFacilityDetail)="onEditGatewayDetail($event)">
    </app-widgets>
</div>
