import { Validators } from "@angular/forms";

export const deviceFieldConfigs = [
  {
    type: 'input',
    name: 'name',
    fieldType: 'text',
    value: '',
    placeholder: 'Group Name',
    class: 'group',
    validation: [
        Validators.compose([Validators.required]),
      ],
      isRequired: true,
  },
  {
    type: 'multiSelect',
    name: 'devices',
    fieldType: 'multiSelect',
    value: '',
    placeholder: 'Select Device',
    class: 'Device',
    options: [],
    validation: [
      Validators.compose([Validators.required]),
    ],
  },
];


