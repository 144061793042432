import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormBuilderComponent } from './dynamic-form-builder.component';
import { ButtonFieldComponent } from '../ui-components/button-field/button-field.component';
import { SharedModule } from '../shared.module';
import { InputFieldComponent } from '../ui-components/input-field/input-field.component';
import { FormFieldDirective } from './directives/form-field.directive';
import { CheckboxFieldComponent } from '../ui-components/checkbox-field/checkbox-field.component';
import { DropdownFieldComponent } from '../ui-components/dropdown-field/dropdown-field.component';
import { MultiselectComponent } from '../ui-components/multiselect/multiselect.component';
import { RadioButtonFieldComponent } from '../ui-components/radio-button-field/radio-button-field.component';


@NgModule({
  declarations: [
    DynamicFormBuilderComponent,
    ButtonFieldComponent,
    InputFieldComponent,
    FormFieldDirective,
    CheckboxFieldComponent,
    DropdownFieldComponent,
    MultiselectComponent,
    RadioButtonFieldComponent
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    
  ],
  
  exports: [
    DynamicFormBuilderComponent,
    ButtonFieldComponent,
    InputFieldComponent,
  ],
})
export class DynamicFormBuilderModule { }
