import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  items: MenuItem[];
  menu: boolean = false;
  pageName: string = "Campus";
  userDetails: any = [];
  isBackButton: any;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
      .subscribe((data: any) => {
        this.pageName = data.title;
      });
    this.userDetails = sessionStorage?.getItem('userDetails');
    this.userDetails = JSON.parse(this.userDetails);
    this.items = [
      {
        label: ` <h2>${this.userDetails?.firstName}   ${this.userDetails?.lastName}</h2>
                                <span class="roleName">Admin</span>`,
        escape: false
      },
      { separator: true },
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => this.logOut()
      }
    ];

    this.isBackButton = sessionStorage.getItem('backToNova');

  }

  navigateToNovaAssure() {
    const url = 'https://fleet.dev.novaassure.com/campus/';
    window.location.href = url;
  }

  logOut() {
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }
}
