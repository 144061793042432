import { Component, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { StorageService } from 'src/app/core/service/storage.service';
import { WebSocketService } from 'src/app/core/service/web-socket.service';
import { HeadersObj } from 'src/app/shared/constants/constants';
import { DevicePenalComponent } from 'src/app/shared/ui-components/device-penal/device-penal.component';
import { FacilityTreeComponent } from 'src/app/shared/ui-components/facility-tree/facility-tree.component';
import { GoogleMapComponent } from 'src/app/shared/ui-components/google-map/google-map.component';
import { GroupFilterComponent } from 'src/app/shared/ui-components/group-filter/group-filter.component';
import { DeviceResponse } from 'src/app/shared/ui-components/map-view/mapModels/map.model';
import { allFacilityDetails, Device, facilities } from '../settings/components/facilities/facilityModels/facilityModels';
import { GroupsService } from '../settings/components/groups/services/groups.service';
import { TableConfig } from './models/campus.model';
import { CampusService } from './services/campus.service';

@Component({
  selector: 'app-campus',
  templateUrl: './campus.component.html',
  styleUrls: ['./campus.component.scss']
})
export class CampusComponent {
  @ViewChild(FacilityTreeComponent) treeView!: FacilityTreeComponent;
  @ViewChild(GroupFilterComponent) groupComp!: GroupFilterComponent;
  @ViewChild(DevicePenalComponent) deviceListComp!: DevicePenalComponent
  @ViewChild(GoogleMapComponent) googleMapComp!: GoogleMapComponent

  facilityResponse: any;
  selectedTreeNode: any;
  selectedGroupId: any;
  selectedFlorId: any;
  selectedFacilityId: any;
  selectedFloorId: any;
  selectedBuildingId: any;
  highlightMarker: any;
  highlightDeviceItem: any
  selectedItems!: any
  groupsConfigFilter: any;
  groupsConfig: any;

  selectedFloorDevices: Device[] = [];
  mainResponse: facilities[] = [];
  showList!: boolean[];
  devicesList: any = [];
  deviceFilterList: any = [];
  tableConfig: TableConfig | undefined;

  hideFacilityTree: boolean = false;
  viewInitialize: boolean = false;
  hideGroupTree: boolean = false;

  activeDeviceList: any = [];
  devicePenalList: any = [];

  clearTimeOut!: NodeJS.Timeout;
  selectionType: string = '';

  constructor(private campusService: CampusService,
    private socketService: WebSocketService,
    private groupsService: GroupsService,
    private storageService: StorageService
  ) {

  }

  getInitView() {
    if (!sessionStorage.getItem('viewInitialized')) {
      this.campusService.getSavedMapConfig().subscribe(res => {
        sessionStorage.setItem('viewInitialized', '1');
        if (res.data) {
          if (res.data.mapType) {
            sessionStorage.setItem("mapType", res.data.mapType);
          }
          if (res.data.selectedMode) {
            this.selectionType = res.data.selectedMode;
            sessionStorage.setItem("selectedMode", res.data.selectedMode);
          }
          if (res.data.zoom) {
            this.storageService.setInSession("zoom", res.data.zoom);
          }
          if (res.data.center) {
            this.storageService.setInSession("center", res.data.center);
          }
          if (res.data.locationIdsForLastView) {
            this.storageService.setInSession("locationIdsForLastView", res.data.locationIdsForLastView);
            if (res.data.selectedMode == 'groupSelection') {
              localStorage.setItem('activeDevices', JSON.stringify(res.data.locationIdsForLastView))
            }
          }
        }
        this.viewInitialize = true
        this.fetchAllApis();
      });
    } else {
      this.viewInitialize = true;
      this.fetchAllApis();
    }
  }

  ngOnDestroy(): void {
    if (this.socketService) {
      this.socketService.disconnectSocket()
    }
  }

  ngOnInit() {
    this.getInitView();
  }

  fetchAllApis() {
    this.getAllFacilitiesDetail();
    this.getAllDevices(false, sessionStorage.getItem("selectedMode") == "groupSelection");
    this.getGroupsFilters();
  }

  getAllFacilitiesDetail() {
    this.campusService.getFacilitiesCampus().subscribe((res: allFacilityDetails) => {
      this.modification(res.data, 'facility');

      // Wrap facility data in a parent "Campus" node
      this.facilityResponse = [{
        label: "Campus",
        children: res.data
      }];

      this.mainResponse = res.data;

      const locationIdsForLastView: any = sessionStorage.getItem('locationIdsForLastView');
      const selectedMode = sessionStorage.getItem("selectedMode");

      // Check if there are stored location IDs and the selected mode is not "groupSelection"
      if (locationIdsForLastView && selectedMode !== "groupSelection") {
        this.selectedTreeNode = this.filterByLastViewIds(res.data, locationIdsForLastView);
        this.googleMapComp.selectedTreeNode = this.selectedTreeNode;
        this.googleMapComp.CreateCampusLocations();
      } else {
        // Handle case when locationIdsForLastView is null or when in groupSelection mode
        this.selectedTreeNode = this.filterByLastViewIds(res.data, locationIdsForLastView);
        this.googleMapComp.selectedTreeNode = this.selectedTreeNode;
        this.googleMapComp.CreateCampusLocations();
        if (!locationIdsForLastView) {
          this.googleMapComp.zoomToMarkers();
        }
      }
    });
  }


  filterByLastViewIds(data: any[], arrIds: any[] | null): any[] {
    // Helper function to recursively filter items
    const recursiveFilter = (item: any): any | null => {
      let matches = [];

      // Check if arrIds is null
      if (arrIds === null) {
        // If arrIds is null, filter based on level property
        if (item.level === 'building') {
          matches.push(item);
        }
      } else {
        // If arrIds is not null, filter based on ID and level type
        if (arrIds.includes(item.id) && item.level == sessionStorage.getItem("selectedMode")) {
          matches.push(item);
        }
      }

      // If there are children, filter them recursively
      if (item.children && item.children.length > 0) {
        for (let child of item.children) {
          const childMatches = recursiveFilter(child);
          if (childMatches) {
            matches.push(...childMatches);
          }
        }
      }

      // Return matches if any are found, otherwise null
      return matches.length > 0 ? matches : null;
    };

    // Apply recursive filter to each top-level item
    return data
      .map(item => recursiveFilter(item))
      .flat()
      .filter((item): item is any => item !== null);
  }



  getAllDevices(isGroupSelected: boolean = false, isDefaultView: boolean = false) {
    this.devicesList = [];
    this.deviceFilterList = [];
    this.devicePenalList = [];
    const payload = {
      facilityId: this.selectedFacilityId,
      buildingId: this.selectedBuildingId,
      floorId: this.selectedFloorId,
      groupId: this.selectedGroupId,
    };

    const getLastUpdate = (asset: any): string | null => {
      const msgDateTime = moment(asset);
      return msgDateTime.isValid() ? moment.duration(moment().diff(msgDateTime)).humanize() + ' ago' : null;
    };
    if(this.deviceListComp){
      this.deviceListComp.loader = true;
    }
    this.campusService.getAllDevices(payload).subscribe(
      {
        next:(res: any) => {
          this.devicesList = res.data;
          this.devicesList.sort((a: any, b: any) => new Date(b.msgDateTime).getTime() - new Date(a.msgDateTime).getTime());

          const ids = this.devicesList.map((asset: any) => {
            const id = asset?.vehicle_id || asset?.id;
            this.deviceFilterList.push({ _id: id, value: asset?.name || asset?.title });
            asset.lastUpdate = asset?.msgDateTime ? getLastUpdate(asset?.msgDateTime): null;
            asset.lastAlert = asset?.eventTime ? getLastUpdate(asset?.eventTime) : null;
            return id;
          });

          this.devicePenalList = this.devicesList;
          this.deviceListComp.loader = false;

          if (isGroupSelected || !isGroupSelected && !isDefaultView) {
            localStorage.setItem('activeDevices', JSON.stringify(ids));
            this.deviceListComp.selectedDevices = ids;
          }

          if (isDefaultView) {
            const locationIds = this.storageService.getFromSession("locationIdsForLastView");
            this.deviceListComp.selectedDevices = locationIds;
            localStorage.setItem('activeDevices', JSON.stringify(locationIds));
          }

          if (isGroupSelected || isDefaultView) {
            this.googleMapComp.devices = this.devicesList;
            this.googleMapComp.createDevices();
            if (isGroupSelected) {
              this.googleMapComp.zoomToMarkers();
            }
          }
        }, 
        error:(err:any) => {
         this.deviceListComp.loader = false;
        }
      });
  }


  convertToTimestamp(dateString: string): number {
    const date = new Date(dateString);
    return date.getTime();
  }

  getActiveDevices(list: any) {
    this.devicesList = [...list];
    if (this.selectionType == 'groupSelection' || sessionStorage.getItem("selectedMode") == "groupSelection") {
      this.googleMapComp.createDevices();
      this.googleMapComp.zoomToMarkers();
    }
  }

  // To manipulate the response for tree view
  modification(data: any[], level: string = '') {
    data.forEach((item: any) => {
      item.label = item.title || item.label;
      item.map_type = item.type;
      delete item['type'];
      item.level = level || item.level;

      let devicesCount = 0;



      if (item?.fixedDevices?.length) {
        devicesCount += item.fixedDevices.length;
      }
      // If there are gateways, count the devices within them
      if (item.gateway?.length) {

        item.gateway.forEach((gatewayItem: any) => {
          devicesCount += gatewayItem.device.length;
        });
      }

      // Recursively process and count devices in children
      if (item.building?.length) {
        item.children = item.building.map((buildingItem: any) => ({
          ...buildingItem,
          label: buildingItem.name,
          level: 'building',
          facilityId: item.id
        }));
        delete item.building;
        this.modification(item.children, 'building');
        item.children.forEach((child: { devicesCount: any; }) => devicesCount += child.devicesCount || 0);
      }
      if (item.floor?.length) {
        item.children = item.floor.map((floorItem: any) => ({
          ...floorItem,
          label: floorItem.label,
          level: 'floor',
          map_type: item.map_type,
          buildingId: item.id,
          facilityId: item.facilityId
        }));
        delete item.floor;
        this.modification(item.children, 'floor');
        item.children.forEach((child: { devicesCount: any; }) => devicesCount += child.devicesCount || 0);
      }

      item.devicesCount = devicesCount;
    });
  }

  // Get selected level form tree
  getSelectedNode(selectedNode: any) {
    this.selectionType = 'campusSelection'
    this.selectedTreeNode = null;
    if (!selectedNode) {
      this.clearGroupFilter();
      return;
    }
    if (selectedNode?.node?.label == 'Level') {
      return;
    }
    this.resetFilterIds();
    this.selectedFloorDevices = [];
    switch (selectedNode?.node?.level) {
      case 'facility':
        this.selectedFacilityId = selectedNode?.node.id;
        this.socketService.disconnectSocket()
        break;
      case 'floor':
        this.devicesList = [];
        this.selectedFloorId = selectedNode?.node.id;
        this.selectedFloorDevices = selectedNode.node?.gateway.device || [];
        this.socketService.connectSocket();
        break;
      case 'building':
        this.socketService.disconnectSocket()
        this.selectedBuildingId = selectedNode?.node.id;
        break;
    }
    

    if (selectedNode) {
      this.selectedTreeNode = [selectedNode?.node];
    } else {
      this.selectedTreeNode = this.facilityResponse;
    }
    if(this.selectedTreeNode[0]?.level !== 'floor'){
      setTimeout(() => {
        this.googleMapComp.selectedTreeNode = this.selectedTreeNode;
        this.googleMapComp.CreateCampusLocations();
        this.googleMapComp.zoomToMarkers();
      }, 0);
    }
    this.getAllDevices();
    
  }

  // Get devices list when toggle enable/disable
  getFloorDevices(devices: DeviceResponse[]) {
    this.selectedFloorDevices = [];
    this.facilityResponse.forEach((facility: any) => {
      if (facility.id == devices[0].facilityId) {
        facility.children.forEach((building: any) => {
          if (building.id == devices[0].buildingId) {
            building.children.forEach((floor: any) => {
              if (floor.id == devices[0].floorId) {
                {
                  building.children.device = devices;
                  this.selectedFloorDevices = [...building.children.device];
                }
              }
            });
          }
        })
      }
    });

  }

  resetMapView() {
    this.treeView.treeElement.selection = null
    this.treeView.selectedNode.next(null);
    this.clearGroupFilter();
    // this.selectedTreeNode = this.facilityResponse;
  }

  getGroupsFilters() {
    this.groupsService.getGroups(HeadersObj).subscribe((groups: any) => {
      this.groupsConfig = groups.data;
    });

  }

  getSelectedGroupItem(item: any) {
    this.selectedTreeNode = null;
    this.selectionType = 'groupSelection'
    this.facilityResponse = null;
    this.selectedFacilityId = null;
    this.selectedBuildingId = null;
    this.selectedFloorId = null;
    setTimeout(() => {
      this.facilityResponse = [{
        label: "Campus",
        children: this.mainResponse
      }];
      this.selectedGroupId = item?.vehicle_group_id;
      // if (item.type) {
      this.onFilterItemSelection(item);
      // }
      this.getAllDevices(true);


    }, 0);
  }

  collectIds(devices: any[]): any[] {
    // Helper function to recursively collect ids
    const getIds = (device: any): any[] => {
      let ids = [device?.id || device?.vehicle_id];

      if (device.children && Array.isArray(device.children)) {
        device.children.forEach((child: any) => {
          ids = ids.concat(getIds(child)); // Recursively collect ids from children
        });
      }

      return ids;
    };

    // Use flatMap to apply the helper function to each device
    return devices.flatMap((device: any) => getIds(device));
  }

  onFilterItemSelection(item: any) {
    // for multi select need to replace the check here....
    if (!item) {
      this.clearGroupFilter();
      return;
    }
    this.selectedFloorDevices = [];
    this.selectedItems = item;

    // For single select.......
    const devices = item?.devices ?? item?.node?.children ?? (item?.node ? [item?.node] : (item ? [item] : []));

    let data: any = [];
    const ids = this.collectIds(devices);
    devices.flatMap((device: any) => {
      // Initialize an array to store ids
      let allIds: any = [];

      // Check if the device has children
      if (device.children && Array.isArray(device.children)) {
        // Add the ids of the children to the allIds array
        allIds = allIds.concat(device.children.map((child: any) => child.id));
      } else {
        allIds = [device.id];
      }

      return allIds;
    });

    // Iterate over each facility
    [...this.mainResponse].forEach((facility: any) => {
      // Filter buildings within the facility
      const filteredBuildings = facility?.children?.map((building: any) => {
        // Filter floors within the building
        const filteredFloors = building?.children?.map((floor: any) => {
          // Filter gateways within the floor
          const filteredGateways = floor?.gateway?.map((gateway: any) => {
            // Filter devices within the gateway
            const filteredDevices = gateway?.device?.filter((device: any) => ids.includes(device?.id || device?.vehicle_id));
            // Return gateway with filtered devices if any devices match
            return filteredDevices?.length > 0 ? { ...gateway, device: filteredDevices } : null;
          }).filter((gateway: any) => gateway !== null); // Remove null values

          // Filter fixed devices within the floor
          const filteredFixedDevices = floor?.fixedDevices?.filter((fixDevice: any) => ids.includes(fixDevice?.vehicle_id));

          // Return floor with filtered gateways and fixed devices if any match
          return filteredGateways?.length > 0 || filteredFixedDevices?.length > 0 ? { ...floor, gateway: filteredGateways, fixedDevices: filteredFixedDevices } : null;
        }).filter((floor: any) => floor !== null); // Remove null values

        // Return building with filtered floors if any floors match
        return filteredFloors?.length > 0 ? { ...building, children: filteredFloors } : null;
      }).filter((building: any) => building !== null); // Remove null values

      // If there are any buildings with matching floors, add the facility to data
      if (filteredBuildings?.length > 0) {
        data.push({ ...facility, children: filteredBuildings });
      }
    });

    this.facilityResponse = [{
      label: "Campus",
      children: data
    }];
    // this.selectedTreeNode = data;
  }



  clearGroupFilter() {
    this.selectionType = 'clearSelection'
    this.resetFilterIds();
    this.selectedFloorDevices = [];
    this.selectedItems = null;
    if (this.treeView) {
      this.treeView.selectedNodes = [];
    }
    // this.resetNodeSelection(this.treeView.treeConfig);
    this.showList = new Array(this.groupsConfig.length).fill(false);
    this.facilityResponse = [{
      label: "Campus",
      children: this.mainResponse
    }];
    this.selectedTreeNode = this.filterByLastViewIds(this.mainResponse, null);
    setTimeout(() => {
      this.googleMapComp.selectedTreeNode = this.selectedTreeNode;
      this.googleMapComp.CreateCampusLocations();
      this.googleMapComp.zoomToMarkers();
      this.getAllDevices();
    }, 0);
  }

  resetNodeSelection(node: any) {
    node.forEach((node: any) => {
      node.partialSelected = false;
      node.expanded = false;
      if (node.children) {
        this.resetNodeSelection(node.children);
      }
    });
  }

  filteredDeviceItem(item: any) {
    this.highlightDeviceItem = item;
    this.highlightMarker = item;
    if (this.clearTimeOut) {
      clearTimeout(this.clearTimeOut);
    }
    this.clearTimeOut = setTimeout(() => {
      this.highlightDeviceItem = null;
    }, 1000);
  }

  resetFilterIds() {
    this.selectedFacilityId = null;
    this.selectedBuildingId = null;
    this.selectedFloorId = null;
    this.selectedGroupId = null;
    if (this.groupComp) {
      this.groupComp.selectedItem = '';
    }
  }

  highlightSelectedDeviceItem(item: any) {
    this.highlightMarker = item;
  }

  //hide device panel
  isHideTreeView(event:any) {
    this.hideFacilityTree = !this.hideFacilityTree;
    setTimeout(() => {
      this.deviceListComp.loader = false;
    }, 0);
  }

}
