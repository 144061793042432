import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { devicePayload } from '../deviceModels/device.model';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  url: any = environment.apiUrl;
  endPoints = ApiEndpoints.settings.Devices
  http = inject(HttpClient);
  constructor() { }
  

  updateDevice(payload: any){
    const url = `${this.url}${this.endPoints.UpdateDevice}`;
    return this.http.post<any>(url, payload);
  }

  getDevices(payload: string | HttpParams){
    const url = `${this.url}${this.endPoints.AllDevices}?pagination=false&limit=25&page=1`;
    return this.http.get<any>(url);
  }

  addDevice(payload:any){
    return this.http.post<devicePayload>(this.url + this.endPoints.AllDevices, payload);
   }
 
   updateDevices(id: number, payload: any) {
     const url = `${this.url}${this.endPoints.AllDevices}/${id}`;
     return this.http.put<any>(url, payload);
   }

   deleteDevice(id: number) {
    const url = `${this.url}${this.endPoints.AllDevices}/${id}`;
    return this.http.delete<any>(url);
  }

  linkDeviceToFloor(payload: any) {
    const url = `${this.url}${this.endPoints.linkToFloor}`;
    return this.http.post<any>(url, payload);
  }

  unlinkFromFloor(id: any) {
    const url = `${this.url}${this.endPoints.unlinkFromFloor}/${id}`;
    return this.http.delete<any>(url);
  }

  deviceCsvImport(payload: FormData){
    const url = `${this.url}${this.endPoints.csvUpload}`;
    return this.http.post<any>(url, payload);
  }
}