import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { markerFieldConfigs } from './marker-constant';
import { markerPayload } from './markersModels/markers.model';
import { MarkersService } from './services/markers.service';
import { BaseComponent } from 'src/app/base.component';
import { ControlName, SelectedTab } from 'src/app/shared/enums/settings.enum';
import { EventService } from 'src/app/shared/services/event.service';
import { HeadersObj } from 'src/app/shared/constants/constants';
import { DialogService } from 'primeng/dynamicdialog';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { CsvImportComponent } from 'src/app/shared/ui-components/dailogs/csv-import/csv-import.component';
import { ImageEditorComponent } from 'src/app/shared/ui-components/image-editor/image-editor.component';

@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent extends BaseComponent implements OnDestroy {
  @ViewChild('form') form!: any;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;

  markerFormConfig = markerFieldConfigs;
  imageUrl: string = ''

  markerWidgetData: any = [];
  icons: any = [];
  editMarkerRowData: any = [];
  tableConfig: any = [];
  selectedTab: string = SelectedTab.markers;
  loaderSubscription: any;
  image: any = '';

  constructor(
    private markerService: MarkersService, 
    private eventService: EventService, 
    cd: ChangeDetectorRef,
    private dialogService: DialogService) {
    super(eventService, cd);
  }

  ngOnInit(){
    this.eventService.loaderService.next(true);
    this.markerService.getMarkersAndIcons(HeadersObj).subscribe((res: any) => {
      this.icons = res[1].data; 
      this.imageUrl = res[1].data[0]; 
      this.markerWidgetData = res[0];
      this.createTableConfig(res[0].data);
      this.eventService.loaderService.next(false);
    });
  }

  //To selectImage
  selectImage(imagePath: string) {
    this.imageUrl = imagePath
  }

  getMarkerIcons() {
    this.markerService.getAllMarkerIcons('').subscribe((res: any) => {
      this.icons = res.data;
      this.imageUrl = res.data[0];
    })
  }

  getMarkerDetail() {
    this.markerService.getAllMarkers('').subscribe((res: any) => {
      this.tableConfig = [];
      this.createTableConfig(res.data);

    })
  }

  onSaveMarkerDetails(formConfig: any, image: any) {
    let payload: markerPayload = {
      title: formConfig?.value?.title,
      image: image.src
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      if (this.editMarkerRowData.id) {
        this.updateFacilityDetails(payload, formConfig);
      } else {
        this.addMarkersDetails(payload, formConfig);
      }
    }
  }

  addMarkersDetails(payload: markerPayload, formConfig: any) {
    this.markerService.addMarker(payload).subscribe((res: any) => {
      this.markerWidgetData?.data.push(res?.data);
      let data = {
        item: res.data,
        image: res.data.image,
        marker: res.data.title != null? res.data.title : '',
        action: {
          edit: true,
          delete: true
        }
      }
      this.tableConfig.data.unshift(data);
      formConfig.reset();
      this.imageUrl = this.icons[0];
    });
  }

  onEditMarkerDetail(data: any) {
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.form?.form.reset();
      this.imageUrl = this.icons[0];
      this.editMarkerRowData = '';
      this.selectedEditRow.selectedRowIndex = -1;
  } else {
      this.selectedEditRow.selectedRowIndex = data.id;
      this.editMarkerRowData = data;
    this.form?.form.controls[ControlName.title].setValue(data.title);
    this.imageUrl = data?.image;
  }
  // this.uploadIcon(this.imageUrl);
  }
  
  updateFacilityDetails(payload: markerPayload, formConfig: any) {
    this.markerService.updateMarker(this.editMarkerRowData.id, payload).subscribe((res: any) => {
      // const existingIndex = this.markerWidgetData.data.findIndex((marker: any) => marker.id === this.editMarkerRowData.id);
      // if (existingIndex !== -1) {
      //   this.markerWidgetData.data[existingIndex] = res.data;
      // }
      // this.getMarkerDetail();
      let data = {
        item: res.data,
        image: res.data.image,
        marker: res.data.title != null? res.data.title : '',
        action: {
          edit: true,
          delete: true
        }
      }
      for(let i =0;i<this.tableConfig.data.length;i++) {
        if(res.data.id == this.tableConfig.data[i].item.id ) {
          this.tableConfig.data.splice(i,1,data);
        }
      }
      formConfig.reset();
      this.imageUrl = this.icons[0];
      this.selectedEditRow.selectedRowIndex = -1;
      this.editMarkerRowData = '';
    });
  }

  onDeleteMarkerDetail(id: number) {
    this.markerService.deleteMarker(id).subscribe(() => {
      let deleteIndex = this.tableConfig.data.findIndex((item: any) => item.item.id == id);
      if (deleteIndex > -1) {
        this.tableConfig.data.splice(deleteIndex, 1);
      }
    });
  }

  //create table config
  createTableConfig(marker: any) {
    const headers = [
      { header: 'Icons', field: 'image' },
      { header: 'Markers', field: 'marker' },
      { header: 'Actions', field: 'action' },
    ];
    const data: any = [];      
    marker.forEach( (e:any) => {
      const config = {
          item: e,
          image: e.image,
          marker: e.title != null? e.title : '',
          action: {
            edit: true,
            delete: true
          }
      }
      data.push(config)  
    });
    this.tableConfig = {header: headers, data: data};
  }

  uploadIcon(imageFile?: any){
     this.dialogService.open(ImageEditorComponent, {
      data: {fileType: 'image/*', imageFile: imageFile},
      header: 'CSV Import',
      width: '70vw',
      height: '100%'
    });
  }
}
