<div class="custom-collapse" [class.collapsed]="isCollapsed">

    <div class="custom-collapse-head"  (click)="isCollapsed = !isCollapsed">
        <h3>Group</h3>
        <i class="pi pi-angle-down collapse-toggle-icon"></i>
    </div>
    <ul  class="custom-collapse-items">
        <li *ngFor="let item of groupsList" [class.active]="selectedItem == item" (click)="getSelectedItem(item)" class="custom-collapse-item">
            <span>{{item?.name}}</span> 
                <span >{{item.totalDevices}}</span>
        </li>
    </ul>
</div>