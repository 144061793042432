import { Component, ElementRef, ViewChild } from '@angular/core';
import { fabric } from 'fabric';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent {
  @ViewChild('canvas', { static: true }) canvasElement!: ElementRef<HTMLCanvasElement>;
  imageChangedEvent: any = null;
  croppedImage: any = '';
  fabricCanvas!: any;
  cropEnabled = false;
  fabricModeEnabled = false;
  imageHistory: string[] = []; // Stack to hold image states
  originalImage: any;
  croppedImageData!: string;

  constructor(public dialogData: DynamicDialogConfig, public ref: DynamicDialogRef) {
    if (dialogData.data.editImage) {
      const imageSource = dialogData.data.editImage;
      if (typeof dialogData.data.editImage == 'object') {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const base64Image = e.target.result;
          this.imageChangedEvent = base64Image;
          this.originalImage = base64Image;
          this.loadImageIntoCanvas(base64Image);
        };
        reader.readAsDataURL(imageSource);
      } else {
        this.imageChangedEvent = imageSource;
        this.originalImage = imageSource;
        this.loadImageIntoCanvas(imageSource);
      }
    }
  }

  ngOnInit() {
    this.fabricCanvas = new fabric.Canvas(this.canvasElement.nativeElement, {
      width: 700,
      height: 400,
    });
    // Set initial background color
    this.fabricCanvas.setBackgroundColor('#FFF', this.fabricCanvas.renderAll.bind(this.fabricCanvas));
    this.fabricCanvas.setZoom(0.9); // 1 means 100% zoom level
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageChangedEvent = e.target.result; // Base64 string
        this.loadImageIntoCanvas(this.imageChangedEvent);
      };

      reader.readAsDataURL(file);
    }
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  toggleCropMode() {
    this.cropEnabled = !this.cropEnabled;
    this.getUpdatedImage().then((imageData) => {
      this.croppedImage = imageData;
    })
    if (!this.cropEnabled) {
      this.croppedImage = '';
    }
  }

  private loadImageIntoCanvas(imageURL: string) {
    fabric.Image.fromURL(imageURL, (img) => {
      this.fabricCanvas.clear();
      img.scaleToWidth(this.fabricCanvas.width);
      this.fabricCanvas.add(img);
    });
  }

  undo() {
      const lastState = this.imageHistory.pop(); // Remove and get the last state
      const img: any = new Image();
      this.imageChangedEvent = lastState || this.originalImage;
      img.src = this.imageChangedEvent;
      img.onload = () => {
        const fabricImage = new fabric.Image(img);
        this.fabricCanvas.clear();
        fabricImage.scaleToWidth(this.fabricCanvas.width);
        this.fabricCanvas.add(fabricImage);
      };
  }

  setView() {
    this.imageHistory.push(this.imageChangedEvent); // Save current state
    this.imageChangedEvent = this.croppedImage
    if (this.fabricCanvas && this.cropEnabled) {
      fabric.Image.fromURL(this.croppedImage, (img) => {
        this.fabricCanvas.clear();
        img.scaleToWidth(this.fabricCanvas.width);
        this.fabricCanvas.add(img);
      });
    }
  }

  setImage() {
    this.getUpdatedImage().then((imageData) => {
      this.ref.close({ image: imageData });
    })
  }

  getUpdatedImage(): Promise<string> {
    let croppedImageData
    return new Promise((resolve, reject) => {
      const selectedObject = this.fabricCanvas.getActiveObject();
      
      if (selectedObject) {
        // Get the bounding box of the selected object
        const boundingBox = selectedObject.getBoundingRect(true); // true to consider rotation
  
        // Create an off-screen canvas
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = boundingBox.width;
        tempCanvas.height = boundingBox.height;
        const tempCtx = tempCanvas.getContext('2d');
  
        // Create a temporary Fabric.js canvas
        const tempFabricCanvas = new fabric.StaticCanvas(null, {
          width: boundingBox.width,
          height: boundingBox.height
        });
  
        // Clone the selected object and adjust its position
        selectedObject.clone((clonedObj: any) => {
          // Adjust the object's position and angle for the new canvas
          clonedObj.set({
            left: clonedObj.left - boundingBox.left,
            top: clonedObj.top - boundingBox.top,
            angle: selectedObject.angle
          });
          tempFabricCanvas.add(clonedObj);
          tempFabricCanvas.renderAll();
  
          // Export the new canvas as an image
          croppedImageData = tempFabricCanvas.toDataURL();
          resolve(croppedImageData);
        }, (error: any) => {
          reject(error);
        });
      } else {
        if(this.croppedImage) {
          croppedImageData = this.croppedImage;
        }else{
          croppedImageData = this.fabricCanvas.toDataURL();
        }
        resolve(croppedImageData);
      }
    });
  }
  

}
