import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AddDeviceComponent } from './add-device.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DynamicFormBuilderModule } from 'src/app/shared/dynamic-form-builder/dynamic-form-builder.module';
import { DialogService } from 'primeng/dynamicdialog';
import { AddDeviceTypeComponent } from './components/add-device-type/add-device-type.component';


@NgModule({
  declarations: [
    AddDeviceComponent,
    AddDeviceTypeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DynamicFormBuilderModule
  ],
  providers: [DialogService
  ],
})

export class DeviceModule {
}