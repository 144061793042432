import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { deviceTypePayload } from '../deviceTypeModels/device-type.model';
import { environment } from 'src/environments/environment';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';

@Injectable({
  providedIn: 'root'
})
export class DeviceTypeService {
  url: any = environment.apiUrl;
  endPoints = ApiEndpoints.settings.DeviceTypes
  http = inject(HttpClient);
  constructor() { }

  getDeviceTypes(payload: string | HttpParams, headers?: any){
    const url = `${this.url}${this.endPoints.DeviceType}?pagination=false&limit=25&page=1`;
    return this.http.get<any>(url);
  }

  addDeviceType(payload:any){
    return this.http.post<deviceTypePayload>(this.url + this.endPoints.DeviceType, payload);
   }
 
   updateDeviceType(id: number, payload: any) {
     const url = `${this.url}${this.endPoints.DeviceType}/${id}`;
     return this.http.put<any>(url, payload);
   }

   deleteDeviceType(id: number) {
    const url = `${this.url}${this.endPoints.DeviceType}/${id}`;
    return this.http.delete<any>(url);
  }

}
