 <div class="facilityDetails">
    <div class="fieldContainer">
        <app-dynamic-form-builder class="facilitiesModule" #form
        [config]="deviceTypeFormConfig">
        </app-dynamic-form-builder>
        <div class="docSaveBtn">
            <app-button-field (click)="onSaveDeviceTypeDetails(form?.form)">  {{ editDeviceTypeRowData?.id ? 'Update' : 'Save' }} </app-button-field>
        </div>
    </div> 
    <div>
        <app-widgets
        [selectedTab]="'deviceType'"
        [tableConfig]="tableConfig"
        (deleteFacilityDetail)="onDeleteDeviceTypeDetail($event)"
        (editFacilityDetail)="onEditDeviceTypeDetail($event)">
        </app-widgets>
    </div>
</div>