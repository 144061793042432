import { Component, inject } from '@angular/core';
import { loginFieldConfigs } from './login-constant';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LoginDetails } from './models/login.model';

@Component({
  selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginFields = loginFieldConfigs;
  authService = inject(AuthService);
  route = inject(Router);

  onLogin(form: any) {
    if (form.valid) {
      this.authService.login(form.value).subscribe((response: LoginDetails) => {
          sessionStorage.setItem('token', response?.accessToken);
          sessionStorage.setItem('refreshToken', response.refreshToken);
          this.route.navigate(['/']);
          sessionStorage.setItem('userDetails', JSON.stringify(response.user));
      })
    }
    else{
      form.markAllAsTouched();
    }
  }
}
