import { Validators } from "@angular/forms";

export const markerFieldConfigs = [
      {
            type: 'input',
            name: 'title',
            fieldType: 'text',
            value: '',
            placeholder: 'Marker Name',
            class: 'title',
            validation: [
              Validators.compose([Validators.required]),
            ],
            isRequired: true,
          },
]