import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { GeofenceListingComponent } from 'src/app/shared/ui-components/geofence-listing/geofence-listing.component';
import { categoryFieldConfig } from './services/add-categorey-type-constant';
import { ConfirmationService } from 'primeng/api';

interface Geofence {
  id: number;
  name: string;
  layer: L.Layer;  // Leaflet layer representing the geofence
  boundaries: {
    xMin: number;  // Min x coordinate (left)
    yMin: number;  // Min y coordinate (bottom)
    xMax: number;  // Max x coordinate (right)
    yMax: number;  // Max y coordinate (top)
  };
  color: string;
  parentId: number | null;
  children: Geofence[];
  class: string,
  expanded: boolean;
}

interface GeofenceCategory {
  id: number;
  name: string;
  description: string;
  geofence: []
}

@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.scss']
})
export class GeofencingComponent implements OnInit {
  @ViewChild(GeofenceListingComponent) geoListComp!: GeofenceListingComponent;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;
  @Input() floorData: any;
  @ViewChild('form') form!: any;
  categoryConfig = categoryFieldConfig;
  map!: L.Map;
  drawnItems!: L.FeatureGroup;

  geofences: any[] = []; // Array to store geofences in hierarchical format
  currentImageLayer!: L.ImageOverlay;
  baseMapLayer!: L.TileLayer;
  geofenceName: string = '';
  geofenceColor: string = '#3388ff'; // Default color
  private originalGeofenceState: any = {};

  categories: any = [
    { id: 0, name: 'Ungrouped', description: 'This is the default category for geofences that are not assigned to any specific group.', geofence: [] },
    { id: 1, name: 'Storage', description: '', geofences: [] },
    { id: 2, name: 'Office', description: '', geofences: [] },
    { id: 3, name: 'Restroom', description: '', geofences: [] },
  ];

  selectedCategory: any = this.categories[0];
  displayCategoryDialog: boolean = false;
  category: GeofenceCategory = { id: 0, name: '', description: '', geofence: [] };
  currentGeofence: any;
  tableConfig: any;
  btnText = "Save";
  lastHighlightedGeofence: any = [];

  constructor(private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.initializeMap();
    this.onEditDetail(this.floorData);
    this.createTableConfig(this.categories);
  }

  initializeMap() {
    this.map = L.map('map', {
      zoom: 2
    });

    this.baseMapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);

    this.drawnItems = new L.FeatureGroup();
    this.map.addLayer(this.drawnItems);

    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        remove: true
      },
      draw: {
        circlemarker: false,
        marker: false,
        polygon: {
          showArea: true,
          shapeOptions: {
            color: 'purple'
          }
        },
        rectangle: {
          shapeOptions: {
            color: 'blue'
          }

        },
        circle: {
          shapeOptions: {
            color: 'green'
          }
        }
      }
    });
    this.map.addControl(drawControl);

    this.toAddGeofence();
  }

  // Handle adding geofences
  toAddGeofence() {
    this.map.on(L.Draw.Event.CREATED, (event: any) => {
      const layer = event.layer;
      let newGeofence: any;
      if (event.layerType === 'circle') {
        const radius = layer.getRadius();
        const latlng = layer.getLatLng();
        newGeofence = this.createGeofence(layer, latlng, radius);

      } else if (event.layerType === 'rectangle' || event.layerType === 'polygon' || event.layerType === 'polyline') {
        const bounds = layer.getBounds();
        newGeofence = this.createGeofence(layer, bounds);
      }

      this.handleGeofenceCreation(newGeofence);
      this.attachContextMenu(newGeofence.layer, event.layerType); // Attach context menu to the layer
    });
  }

  // Helper to create a geofence with layer details
  createGeofence(layer: any, boundsOrCenter: any, radius?: number) {
    const newGeofence: any = {
      id: new Date().getTime(),
      name: this.geofenceName || 'Default',
      color: this.geofenceColor,
      layer: layer,
      boundaries: radius ? {
        center: boundsOrCenter,
        radius: radius
      } : {
        xMin: boundsOrCenter.getSouthWest().lng,
        yMin: boundsOrCenter.getSouthWest().lat,
        xMax: boundsOrCenter.getNorthEast().lng,
        yMax: boundsOrCenter.getNorthEast().lat
      },
      parentId: null,
      children: [],
      expanded: false
    };

    return newGeofence;
  }

  // Handle geofence creation logic (whether it's inside another or not)
  handleGeofenceCreation(newGeofence: any) {
    let parentGeofence: any = null;
    for (const category of this.geofences) {
      for (const geofence of category.geofences) {
        if (this.isGeofenceInside(geofence, newGeofence, category.id)) {
          parentGeofence = geofence;
          break;
        }
      }
    }

    if (parentGeofence) {
      parentGeofence.class = 'sub-child';
      parentGeofence.children.push(newGeofence);
      newGeofence.parentId = parentGeofence.id;
    } else {
      let existingCategory = this.geofences.find(cat => cat.id === this.selectedCategory.id);
      if (existingCategory) {
        existingCategory.geofences.push(newGeofence);
      } else {
        this.geofences.push({
          id: this.selectedCategory.id,
          name: this.selectedCategory.name,
          description: this.selectedCategory.description,
          geofences: [newGeofence]
        });
      }
    }

    newGeofence.layer.setStyle({ color: newGeofence.color });
    newGeofence.layer.bindTooltip(newGeofence.name, {
      permanent: true,
      position: 'center'
    }).openTooltip();
    this.drawnItems.addLayer(newGeofence.layer);
    this.updateGeofenceList();
  }

  // Attach context menu to a layer
  attachContextMenu(layer: any, type: string) {
    layer.on('contextmenu', (e: any) => {
      this.showContextMenu(e, layer, type);
    });
  }

  // Show custom context menu
  showContextMenu(event: any, geofenceLayer: any, type: string) {
    // Create the menu container if it doesn't exist
    let menu = document.getElementById('custom-context-menu');
    if (!menu) {
      menu = document.createElement('div');
      menu.id = 'custom-context-menu';
      menu.style.position = 'absolute';
      menu.style.backgroundColor = 'white';
      menu.style.border = '1px solid black';
      menu.style.padding = '10px';
      menu.style.zIndex = '1000';
      document.body.appendChild(menu);
    }

    // Position the context menu at the mouse position
    menu.style.left = `${event.originalEvent.pageX}px`;
    menu.style.top = `${event.originalEvent.pageY}px`;

    // Clear the previous menu items
    menu.innerHTML = '';

    // Add "Edit" option to the context menu
    const editOption = document.createElement('div');
    editOption.innerHTML = '<i class="pi pi-pen-to-square"></i> Edit Geofence';
    editOption.style.cursor = 'pointer';
    editOption.onclick = () => {
      this.enableGeofenceEditing(geofenceLayer); // Enable editing mode
      this.hideContextMenu(); // Hide the context menu after selection
    };
    menu.appendChild(editOption);

    // Add "Copy" option conditionally based on the geofence type
    if (geofenceLayer && (type === 'circle' || type === 'rectangle')) {
      const copyOption = document.createElement('div');
      copyOption.innerHTML = '<i class="pi pi-clone"></i> Copy Geofence';
      copyOption.style.cursor = 'pointer';
      copyOption.onclick = () => {
        this.copyGeofence(geofenceLayer); // Copy the selected geofence
        this.hideContextMenu(); // Hide the context menu after selection
      };
      menu.appendChild(copyOption);
    }

    // Add "Delete" option to the context menu
    // const deleteOption = document.createElement('div');
    // deleteOption.innerText = 'Delete Geofence';
    // deleteOption.style.cursor = 'pointer';
    // deleteOption.onclick = () => {
    //   // this.deleteGeofence(geofenceLayer); // Delete the selected geofence
    //   this.hideContextMenu(); // Hide the context menu after selection
    // };
    // menu.appendChild(deleteOption);

    // Option to close the menu
    const closeOption = document.createElement('div');
    closeOption.innerHTML = '<i class="pi pi-times"></i> Close Menu';
    closeOption.style.cursor = 'pointer';
    closeOption.onclick = () => {
      this.hideContextMenu();
    };
    menu.appendChild(closeOption);
  }


  // Copy the geofence and create a new one with an offset
  copyGeofence(originalLayer: any) {
    let copiedLayer: any;
    const offsetDistance = 0.0005; // Small offset to avoid exact overlap
    let type: string = '';

    // Determine the type of the original layer
    if (originalLayer instanceof L.Circle) {
      type = 'circle';
      // Copying a Circle geofence
      const newCenter = L.latLng(
        originalLayer.getLatLng().lat + offsetDistance,
        originalLayer.getLatLng().lng + offsetDistance
      );
      copiedLayer = L.circle(newCenter, {
        radius: originalLayer.getRadius(),
        color: originalLayer.options.color
      }).addTo(this.map);
    } else if (originalLayer instanceof L.Rectangle) {
      type = 'rectangle';
      // Copying a Rectangle geofence
      const newLatLngs = originalLayer.getLatLngs().map((latlngArray: any) =>
        latlngArray.map((latlng: any) => L.latLng(latlng.lat + offsetDistance, latlng.lng + offsetDistance))
      );
      copiedLayer = L.rectangle(newLatLngs, {
        color: originalLayer.options.color
      }).addTo(this.map);
    } else if (originalLayer instanceof L.Polygon) {
      type = 'polygon';
      // Copying a Polygon geofence
      const newLatLngs = originalLayer.getLatLngs().map((latlngArray: any) =>
        latlngArray.map((latlng: any) => L.latLng(latlng.lat + offsetDistance, latlng.lng + offsetDistance))
      );
      copiedLayer = L.polygon(newLatLngs, {
        color: originalLayer.options.color
      }).addTo(this.map);
    }

    // Bind the copied layer to tooltip and add it to the drawnItems group
    if (copiedLayer) {
      const copiedGeofence = this.createGeofence(copiedLayer, copiedLayer.getBounds() || copiedLayer.getLatLng());
      this.handleGeofenceCreation(copiedGeofence);

      // Attach the context menu and enable editing mode for the copied layer
      this.attachContextMenu(copiedLayer, type);
      this.enableGeofenceEditing(copiedLayer); // Enable edit mode for the copied geofence
    }
  }




  // Hide the context menu
  hideContextMenu() {
    const menu = document.getElementById('custom-context-menu');
    if (menu) {
      menu.style.left = '-9999px'; // Move it off-screen
    }
  }

  // Enable editing mode for the selected geofence
  enableGeofenceEditing(geofenceLayer: any) {
    geofenceLayer.editing.enable(); // Enable edit mode

    // Create buttons to save or cancel changes
    const saveButton = L.DomUtil.create('button', '', this.map.getContainer());
    saveButton.innerHTML = 'Save';
    saveButton.style.position = 'absolute';
    saveButton.style.top = '10px';
    saveButton.style.right = '10px';

    const cancelButton = L.DomUtil.create('button', '', this.map.getContainer());
    cancelButton.innerHTML = 'Cancel';
    cancelButton.style.position = 'absolute';
    cancelButton.style.top = '10px';
    cancelButton.style.right = '60px';

    // this.onEditGeofence()
    // Handle save changes

    this.originalGeofenceState[geofenceLayer._leaflet_id] = {
      type: geofenceLayer instanceof L.Circle ? 'circle' : (geofenceLayer instanceof L.Rectangle ? 'rectangle' : 'polygon'),
      latlngs: geofenceLayer instanceof L.Circle ? [geofenceLayer.getLatLng()] : geofenceLayer.getLatLngs()
    };

    L.DomEvent.on(saveButton, 'click', () => {
      geofenceLayer.editing.disable(); // Disable edit mode
      this.saveGeofenceChanges(geofenceLayer);
      this.map.getContainer().removeChild(saveButton);
      this.map.getContainer().removeChild(cancelButton);
    });

    // Handle cancel changes
    L.DomEvent.on(cancelButton, 'click', () => {
      geofenceLayer.editing.disable(); // Disable edit mode
      this.cancelGeofenceChanges(geofenceLayer); // Revert to original shape
      this.map.getContainer().removeChild(saveButton);
      this.map.getContainer().removeChild(cancelButton);
    });
  }

  // Save geofence changes after editing
  saveGeofenceChanges(geofenceLayer: any) {
    // Handle saving updated geofence coordinates or properties
    alert('Geofence changes saved!');
    // Update the geofence's boundaries in the model if needed
  }

  // Cancel geofence changes (revert to original)
  cancelGeofenceChanges(geofenceLayer: any) {
    // Revert the geofence to its original state
    const originalState = this.originalGeofenceState[geofenceLayer._leaflet_id];
    if (originalState) {
      if (originalState.type === 'circle') {
        geofenceLayer.setLatLng(originalState.latlngs[0]);
      } else if (originalState.type === 'rectangle' || originalState.type === 'polygon') {
        geofenceLayer.setLatLngs(originalState.latlngs);
      }

      // Remove the original state from the record
      delete this.originalGeofenceState[geofenceLayer._leaflet_id];
    }

    alert('Geofence changes canceled!');
  }

  // Function to check if newGeofence is inside parentGeofence (bounding box check)
  isGeofenceInside(parentGeofence: Geofence, newGeofence: Geofence, categoryId: any): boolean {
    const parentBounds = parentGeofence.boundaries;
    const newBounds = newGeofence.boundaries;

    return (
      categoryId == this.selectedCategory.id &&
      newBounds.xMin >= parentBounds.xMin &&
      newBounds.yMin >= parentBounds.yMin &&
      newBounds.xMax <= parentBounds.xMax &&
      newBounds.yMax <= parentBounds.yMax
    );
  }



  saveGeofences() {
    // localStorage.setItem('geofences', JSON.stringify(this.geofences));
  }

  loadGeofences() {
    const savedGeofences = JSON.parse(localStorage.getItem('geofences') || '[]');
    savedGeofences.forEach((geofence: any) => {
      let layer: L.Layer;
      if (geofence.layer.type === 'circle') {
        layer = L.circle(geofence.layer.latlngs, geofence.layer.options);
      } else if (geofence.layer.type === 'polygon') {
        layer = L.polygon(geofence.layer.latlngs, geofence.layer.options);
      } else {
        return; // Skip unsupported types
      }
      this.drawnItems.addLayer(layer);
      this.geofences.push({
        ...geofence,
        layer: layer,
        children: [] // Initialize children array
      });
    });
    this.updateGeofenceList();
  }

  updateGeofenceList() {
    // Trigger change detection if needed
  }

  onEditDetail(floor: any) {
    const imageUrl = floor.image;

    if (this.currentImageLayer) {
      this.map.removeLayer(this.currentImageLayer);
    }

    if (this.baseMapLayer) {
      this.map.removeLayer(this.baseMapLayer);
    }

    const imageBounds: any = [
      [0, 0], // Southwest corner
      [floor.dy_position, floor.dx_position] // Northeast corner
    ];

    this.currentImageLayer = L.imageOverlay(imageUrl, imageBounds).addTo(this.map);
    this.map.fitBounds(this.currentImageLayer.getBounds());
    // this.map.setZoom(5);
  }

  saveGeofence() {

  }

  highlightGeofence(geofenceGroup: any) {
    // Reset the last highlighted geofences to their original colors
    this.lastHighlightedGeofence.forEach((geofence: any) => {
      (geofence.layer as any).setStyle({ color: geofence.color });
    });

    let lastGeofences: any[] = [];

    // Check if geofenceGroup is an object (single geofence) or an array of geofences
    if (typeof geofenceGroup === 'object' && !Array.isArray(geofenceGroup.geofences)) {
      // Highlight single geofence
      if (!this.lastHighlightedGeofence.includes(geofenceGroup)) {
        (geofenceGroup.layer as any).setStyle({ color: '#ff7800' }); // Highlight color
        this.map.fitBounds(geofenceGroup.layer.getBounds()); // Fit map to bounds of the geofence
        lastGeofences = [geofenceGroup]; // Store with set color
      }
      else {
        this.map.fitBounds(this.currentImageLayer.getBounds());
      }
    } else if (Array.isArray(geofenceGroup.geofences)) {
      if (this.lastHighlightedGeofence.length == 1) {
        this.lastHighlightedGeofence = []
      }
      // Highlight multiple geofences
      geofenceGroup.geofences.forEach((geofence: any) => {
        this.drawnItems.eachLayer((layer: L.Layer) => {
          if (geofence.layer === layer && !this.lastHighlightedGeofence.includes(geofence)) {
            lastGeofences.push(geofence); // Store with set color
            (geofence.layer as any).setStyle({ color: '#ff7800' }); // Highlight color
          }
        });
      });
    }

    // Update the last highlighted geofences
    this.lastHighlightedGeofence = lastGeofences;
  }



  onEditGeofence(geofence: any) {
    this.geoListComp.currentGeofence = geofence.geofence;

    if (this.geofenceName !== '') {
      // Update geofence details
      this.geoListComp.currentGeofence.name = this.geofenceName;
      this.geoListComp.currentGeofence.layer.setStyle({ color: this.geofenceColor }); // Apply selected color
      this.geoListComp.currentGeofence.layer.bindTooltip(this.geofenceName, {
        permanent: true,
      }).openPopup();

      // Check if the geofence's category has changed
      if (geofence.category.id !== this.selectedCategory.id) {

        // Find or create the selected category
        let newCategory = this.geofences.find((cat: any) => cat.id === this.selectedCategory.id);
        if (!newCategory) {
          this.selectedCategory.geofences = [geofence.geofence];
          this.geofences.push(this.selectedCategory);
          // Find the existing category by geofence's current categoryId
          const currentCategory = this.geofences.find((cat: any) => cat.id === geofence.category.id);

          // Remove the geofence from the current category if it exists
          if (currentCategory) {
            currentCategory.geofences = currentCategory.geofences.filter((g: any) => g.id !== geofence.geofence.id);
            // If the category is now empty, remove it from the list
            if (currentCategory.geofences.length === 0) {
              this.geofences = this.geofences.filter((cat: any) => cat.id !== currentCategory.id);
            }

          }
        }
        else {
          // Find the existing category by geofence's current categoryId
          const currentCategory = this.geofences.find((cat: any) => cat.id === geofence.categoryId);
          // Remove the geofence from the current category if it exists
          if (currentCategory) {
            currentCategory.geofences = currentCategory.geofences.filter((g: any) => g.id !== geofence.geofence.id);
            // If the category is now empty, remove it from the list
            if (currentCategory.geofences.length === 0) {
              this.geofences = this.geofences.filter((cat: any) => cat.id !== currentCategory.id);
            }
            // Add the geofence to the selected category
            newCategory.geofences.push(geofence.geofence);
          }
        }


      }

      // Clear input fields after saving
      this.geofenceName = '';
      this.geofenceColor = '#3388ff';
      this.geoListComp.currentGeofence = null;
    } else {
      // Populate input with the current name if no new name is provided
      this.geofenceName = geofence.geofence.name;
    }
  }



  goBack() {
    // Implement navigation or functionality to go back
  }

  saveCategory(formConfig: any ) {
    let payload: GeofenceCategory = {
      ...formConfig?.value
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      if(this.btnText == 'Save') {
        payload['id'] = this.categories.length + 1;
        payload['geofence'] = [];
        this.categories.push(payload);
        this.form.form.reset();
      } else if(this.btnText = 'Update') {
        debugger
        let index = this.categories.findIndex((item:GeofenceCategory) => item.id == this.category.id);
        this.categories[index].name = this.form.form.controls['name'].value;
        this.categories[index].description = this.form.form.controls['description'].value;
        this.onEditCategory(this.category);
      }
      this.createTableConfig(this.categories);
    }
  }

  createNewCategory() {
    this.displayCategoryDialog = true;
  }

  deleteCategory(id: string) {

  }

  deleteSelectedGeofence(data: any) {

    this.confirmationService.confirm({
      header: 'Warning',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure that you want to delete the selected geofence',
      accept: () => {
        const category = this.geofences.find((g: any) => g.id === data.categoryId);
        if (category) {
          // Check if the geofence is a child of another geofence
          if (data.geofence.parentId) {
            const parentGeofence = category.geofences.find((g: any) => g.id === data.geofence.parentId);
    
            if (parentGeofence) {
              // Remove the geofence from the parent's children
              parentGeofence.children = parentGeofence.children.filter((child: any) => child.id !== data.geofence.id);
            }
          } else {
            // Remove the geofence directly from the category's geofences
            category.geofences = category.geofences.filter((g: any) => g.id !== data.geofence.id);
          }
          this.drawnItems.removeLayer(data.geofence.layer);
        }
      },
      reject: () => {
        return;
      }
    });
  }

  createTableConfig(category: any) {
    const actionCheck = ((name: any) => name !== "Ungrouped")
    const headers = [
      { header: 'Name', field: 'name' },
      { header: 'Description', field: 'description' },
      { header: 'Actions', field: 'action' }
    ];
    const data: any = [];
    category.forEach((element: any) => {
      let config = {
        item: element,
        name: element.name,
        description: element.description,
        action: { edit: actionCheck(element.name), delete: actionCheck(element.name) }
      }
      data.push(config);
    });
    this.tableConfig = { header: headers, data: data };
  }
  
  onDeleteCategory(id:number) {
    this.confirmationService.confirm({
      header: 'Warning',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure that you want to delete the category',
      accept: () => {
        let index = this.categories.findIndex((item:GeofenceCategory) => item.id == id);
        this.categories.splice(index, 1);
        this.createTableConfig(this.categories);
      },
      reject: () => {
        return;
      }
    });
  }

  onEditCategory(data:any) {
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.selectedEditRow.selectedRowIndex = -1;
      this.form.form.reset();
      this.btnText = "Save"
    } else {
      this.selectedEditRow.selectedRowIndex = data.id;
      this.btnText = "Update"
      this.category = { ...data }
      this.form.form.controls['name'].setValue(data.name);
      this.form.form.controls['description'].setValue(data.description);
    }
  }

  onHide(event:any) {
    this.selectedEditRow.selectedRowIndex = -1;
    this.form.form.reset();
    this.btnText = "Save"
  }
}
