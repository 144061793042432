
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Output() imageFile = new EventEmitter<any>();
  imageUrl: any;

  onFileSelect(event: any) {
    if (event.files && event.files.length > 0) {
      const file = event.files[0];
      const reader = new FileReader();
  
      reader.onload = (e: any) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
  
          // if (img.width === 1920 && img.height === 1080) {
            this.imageUrl = img.src
            this.imageFile.next(file);
            // File is valid, proceed with the upload
            // You can add your upload logic here
          // } 
          // else {
          //   // Reset file input and show error message
          //   this.clearFileUpload();
          //   alert('Please upload an image with dimensions 1920x1080 pixels.');
          // }
        };
      };
  
      reader.readAsDataURL(file);
    }
  }
  
  clearFileUpload() {
    // Clear file input
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    if (fileUpload) {
      this.imageUrl = null;
      fileUpload.value = '';
      this.imageFile.next(null);
    }
  }
  
}
