import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FieldConfig } from '../dynamic-form-builder/models/field-config.interface';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormCreation {

  constructor(public fb: FormBuilder) { }

  createGroup(config: any) {
    const group = this.fb.group({});
    config.forEach((control: any) => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  createControl(config: FieldConfig) {

    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation as any);
  }
}
